import { enqueueSnackbar } from "notistack";
import { Box, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { useMutation } from "react-query";
import { useEffect } from "react";

import { resetPasswordValidate } from "../../validates/resetPasswordValidate";
import { resetPassword } from "../../api/auth";
import Input from "../../components/common/input/Input";
import Button from "../../components/common/button/Button";

const ResetPasswordForm = () => {
  const navigator = useNavigate();

  const { isLoading, isSuccess, mutate, isError, error } =
    useMutation(resetPassword);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      enqueueSnackbar("Password changed", { variant: "success" });
      navigator("/");
    }

    if (isError) {
      const errorData = error?.response?.data?.message;
      enqueueSnackbar(errorData, { variant: "error" });
    }
  }, [isSuccess, isError, isLoading, error?.response?.data?.message, navigator]);

  return (
    <Formik
      initialValues={{
        newPassword: "",
        confirmPassword: "",
      }}
      onSubmit={({ newPassword, confirmPassword }) => {
        const authData = `{"newPassword":"${newPassword}", "confirmPassword": "${confirmPassword}"}`;
        let params = new URL(window.location).searchParams;
        let id = params.get("id");
        let token = params.get("token");

        mutate({
          id,
          token,
          authData,
        });
      }}
      validationSchema={resetPasswordValidate}
    >
      {({ errors, touched }) => {
        return (
          <Form>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  gap: "5rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Typography
                    sx={{ paddingBottom: "1.5rem" }}
                    variant="h2"
                    component="h2"
                  >
                    Reset Password
                  </Typography>
                  <Typography variant="h4" component="h4">
                    Log in with your user details to continue
                  </Typography>
                </Box>
                <Box
                  sx={{
                    gap: "2rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Field
                    component={Input}
                    label="New password"
                    name="newPassword"
                    type="password"
                    placeholder="Password"
                    error={errors?.newPassword && touched?.newPassword}
                    helperText={errors?.newPassword}
                  />
                  <Field
                    component={Input}
                    label="Confirm password"
                    name="confirmPassword"
                    type="password"
                    placeholder="Password"
                    error={errors?.confirmPassword && touched?.confirmPassword}
                    helperText={errors?.confirmPassword}
                  />
                </Box>
                <Grid container direction="column" spacing={5}>
                  <Grid item>
                    <Button type="submit" isLoading={isLoading}>
                      Confirm Details
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ResetPasswordForm;
