import cn from 'classnames';
import './StylesButton.scss';

const Button = ({ children, onClick, isModal, type, isLoading, disabled }) => {

  return(
  <div className={`ButtonBox ButtonBox`}>
    <button
      disabled={isLoading || disabled}
      className={cn('Button', {
        'Button__loading': isLoading,
        'Button__disabled': disabled,
        'Button__modal': isModal,
      })} type={type} onClick={onClick}
    >
      <span className={cn('', {
        'Button__loading__text': isLoading,
        'Button__modal': isModal
      })}>
          {children}
      </span>
    </button>
  </div>
)
    }
export default Button;