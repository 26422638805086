import * as Yup from 'yup';

export const createAdminValidate = Yup.object().shape({
  email: Yup.string()      
    .email('Invalid email format')
    .required('Field is required'),
  status: Yup.string()
    .required('This field in required!'),
  firstName: Yup.string()
    .required('This field in required!'),
    password: Yup.string()
    .required('This field in required!'),
  roleId: Yup.string()
    .required('This field in required!'),
});