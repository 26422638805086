import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { useFormikContext } from "formik";

export default function BasicTimePicker() {
  // const { values, setFieldValue } = useFormikContext();
  const { setFieldValue, values: { sendOnTime } } = useFormikContext();
  // const sendTime = values.sendTime; // Assuming you have a "sendTime" field in your form

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          value={sendOnTime ? dayjs(sendOnTime) : null}
          onChange={(newValue) => setFieldValue("sendOnTime", newValue?.format())}
        />
    </LocalizationProvider>
  );
}
