import { useState, useEffect, useCallback } from "react"
import { useQuery, useMutation } from "react-query"
import { useFormikContext } from "formik"
import { createArtist, getArtist } from "../../api/artist"
import DropZone from "../common/dropZone/DropZone"
import SprayMoneyBalance from "../sprayMoneyBalance/SprayMoneyBalance"
import { Box, Grid } from "@mui/material"
import ChooseArtistInput from "./components/ChooseArtistInput"
import useDebounce from "../../hooks/useDebounce"
import "./StylesArtistBlock.scss"

const ArtistBlock = () => {
  const { setFieldValue, values, errors } = useFormikContext()
  const [artistSearchTerm, setArtistSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(artistSearchTerm, 300)

  const {
    data: artistsData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["artists"],
    queryFn: () => getArtist({ s: debouncedSearchTerm }),
    retry: false,
  })

  const {
    data: newArtistData,
    mutate,
    error: createArtistError,
  } = useMutation(value => createArtist({ value }))

  useEffect(() => {
    refetch()
    // eslint-disable-next-line
  }, [debouncedSearchTerm])

  useEffect(() => {
    if (newArtistData?.data) {
      setFieldValue("artistId", newArtistData?.data?.id)
      setFieldValue("image", newArtistData?.data?.imageUrl)
      setFieldValue("artistName", newArtistData?.data?.name)
    }
    // eslint-disable-next-line
  }, [newArtistData])

  const handleCreateArtist = useCallback(
    ({ stageName }) => {
      mutate({ stageName, imageUrl: values?.image || "" })
    },
    [mutate, values.image]
  )

  const handleSelectArtist = useCallback(
    artist => {
      setFieldValue("image", artist?.profileImageUrl)
      setFieldValue("artistId", artist?.id)
      setFieldValue("artistName", artist?.fullName)
    },
    [setFieldValue]
  )

  return (
    <Grid item xs={12} md={12} lg={3} xl={2}>
      <div className="ArtistBlock">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={12}>
            <DropZone />
          </Grid>
          <Grid item xs={12} sm={6} lg={12}>
            <Box sx={{ padding: "0 0 2rem" }}>
              <div className="ArtistBlock__user">
                <div className="ArtistBlock__user__data">
                  <ChooseArtistInput
                    options={artistsData?.data || []}
                    isLoading={isLoading}
                    setArtistSearchTerm={setArtistSearchTerm}
                    handleCreateArtist={handleCreateArtist}
                    handleSelectArtist={handleSelectArtist}
                    createArtistError={
                      createArtistError?.response?.data?.data[0]?.message
                    }
                    errors={errors}
                  />
                </div>
              </div>
            </Box>
            <SprayMoneyBalance
              isArtist
              balance="5000"
              title="Stream Passes Sold"
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  )
}

export default ArtistBlock
