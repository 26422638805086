import { Field } from 'formik'
import { Box } from '@mui/material';

import './StylesPurchaseInformation.scss';

const PurchaseInformation = () => (
  <div className='PurchaseInformation'>
    <div className='PurchaseInformation__label'>
      Purchase Information
    </div>
    <div className='PurchaseInformation__wrapper'>
      <div className='PurchaseInformation__wrapper--label'>
        Allow Payment Methods
      </div>
      <div className='StreamPurchaseInformationForm__form__checkbox--group'>
        <Box sx={{
          display: 'flex',
          gap: 2
        }}>
          <label className='formikCheckBox'>
            <Field 
              type='checkbox'
              name='sprayMoneyAllowStripe'
            />
            Stripe
          </label>
          <label className='formikCheckBox'>
            <Field 
              type='checkbox'
              name='sprayMoneyAllowPaystack'
            />
            Paystack
          </label>
          <label className='formikCheckBox'>
            <Field
              type='checkbox'
              name='sprayMoneyAllowMTN'
            />
            MTN
          </label>
        </Box>
      </div>
    </div>
  </div>
)

export default PurchaseInformation;