import "./StylesArtistLayout.scss";
import ArtistInformationBlock from "../../components/artistInformationBlock/ArtistInformationBlock";

const ArtistLayout = ({ children, imgFieldName, imgType }) => (
  <div className="CrmLayout">
    <ArtistInformationBlock
      imgFieldName={imgFieldName}
      imgType={imgType}
    />
    <div className="CrmLayout__content">{children}</div>
  </div>
);

export default ArtistLayout;
