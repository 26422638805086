import { useMutation } from 'react-query';
import { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Box, Typography } from '@mui/material';
import { Formik, Field, Form } from 'formik';

import { reopenOrder } from '../../../api/orders';
import Select from '../../common/select/Select';
import Modal from '../../common/modal/Modal';
import Button from '../../common/button/Button';
import './StylesReopenOrderModal.scss';
  
const chooseReason = [
  { label: 'Payment Provider Flagged', value: 'PAYMENT_PROVIDER_FLAGGED' },
  { label: 'Fraudulent transaction', value: 'FRAUDULENT' },
  { label: 'Suspicious Account Activity', value: 'SUSPICIOUS_ACCOUNT_ACTIVITY' },
  { label: 'Invalid User details', value: 'INVALID_USER_DETAILS' },
];

const ReopenOrderModal = ({ handleClose, selectTransactionId, refetch }) => {
  const {
    data,
    isError,
    isLoading,
    isSuccess,
    mutate,
    error
  } = useMutation(({ selectTransactionId, reason }) => reopenOrder({ selectTransactionId, reason }));

  useEffect(() => {
    if (!isLoading && !isError && isSuccess) {
      refetch();
      enqueueSnackbar(data?.data?.message, { variant: 'success' });
      handleClose();
    }

    if (!isLoading && isError) {
      const erorrData = error?.response?.data?.data;

      erorrData?.forEach(({ field, message }) => enqueueSnackbar(`${field ? field + ':' : ''}${message}`, { variant: 'error' }));
      handleClose();
    }
  }, [isError, isLoading, isSuccess, data?.data?.message, error?.response?.data?.data, handleClose, refetch])

  const handleReopen = ({ reason }) => mutate({ selectTransactionId, reason });

  return (
    <Modal
      title='Reopen Order'
      action='Confirm Refund'
      onClose={handleClose}
    >
      <Box
      >
        <Typography
          sx={{
            textAlign: 'center'
          }} variant="h5" gutterBottom
        >
          Please confirm that you want to remove the stream passes attached to the from this transaction
        </Typography>
        <Formik
          initialValues={{
            reason: '',
          }}
          onSubmit={handleReopen}
        >
          <Form>
            <Field
              component={Select}
              label='Choose Reason'
              options={chooseReason}
              name='reason'
            />
            <Box sx={{
              textAlign: 'center',
              marginTop: '4rem',
            }}>
              <Button
                isModal
                isLoading={isLoading}
              >
                Confirm Refund
              </Button>
            </Box>
          </Form>
        </Formik>
      </Box>
    </Modal>
  )
} 

export default ReopenOrderModal;