export const setInitialStateForm = (singleEventData, passes) => {
  const $passes = [
    {
      type: "LIVESTREAM",
      mode: "LIVESTREAM",
      isEnabled: false,
      title: "",
      description: "",
      eventPageDescription: "",
      streamUrl: "",
      maxPerUser: "",
      priceUSD: "",
      priceGBP: "",
      priceNGN: "",
      priceMTN: "",
      allowStripe: false,
      allowPaystack: false,
      allowMTN: false,
      passCoverUrl: "",
      mtnServiceId: "",
    },
    {
      type: "BACKSTAGE",
      mode: "LIVESTREAM",
      isEnabled: false,
      title: "",
      description: "",
      eventPageDescription: "",
      streamUrl: "",
      maxPerUser: "",
      priceUSD: "",
      priceGBP: "",
      priceNGN: "",
      priceMTN: "",
      allowStripe: false,
      allowPaystack: false,
      allowMTN: false,
      passCoverUrl: "",
      mtnServiceId: "",
    },
    {
      mode: "LIVESTREAM",
      type: "ON_DEMAND",
      isEnabled: false,
      title: "",
      description: "",
      streamUrl: "",
      videoContent: [
        {
          videoTitle: "",
          videoUrl: "",
          videoDescription: "",
          videoCoverArt: "",
        },
      ],
      maxPerUser: "",
      priceUSD: "",
      priceGBP: "",
      priceNGN: "",
      priceMTN: "",
      allowStripe: false,
      allowPaystack: false,
      allowMTN: false,
      passCoverUrl: "",
      mtnServiceId: "",
    },
  ]
  const $onDemandPasses = [
    {
      type: "LIVESTREAM",
      mode: "ON_DEMAND",
      isEnabled: false,
      title: "",
      description: "",
      streamUrl: "",
      eventPageDescription: "",
      maxPerUser: "",
      priceUSD: "",
      priceGBP: "",
      priceNGN: "",
      priceMTN: "",
      allowStripe: false,
      allowPaystack: false,
      allowMTN: false,
      passCoverUrl: "",
      mtnServiceId: "",
    },
    {
      type: "BACKSTAGE",
      mode: "ON_DEMAND",
      isEnabled: false,
      title: "",
      description: "",
      streamUrl: "",
      eventPageDescription: "",
      maxPerUser: "",
      priceUSD: "",
      priceGBP: "",
      priceNGN: "",
      priceMTN: "",
      allowStripe: false,
      allowPaystack: false,
      allowMTN: false,
      passCoverUrl: "",
      mtnServiceId: "",
    },
    {
      type: "ON_DEMAND",
      mode: "ON_DEMAND",
      isEnabled: false,
      title: "",
      description: "",
      streamUrl: "",
      videoContent: [
        {
          videoTitle: "",
          videoUrl: "",
          videoDescription: "",
          videoCoverArt: "",
        },
      ],
      maxPerUser: "",
      priceUSD: "",
      priceGBP: "",
      priceNGN: "",
      priceMTN: "",
      allowStripe: false,
      allowPaystack: false,
      allowMTN: false,
      passCoverUrl: "",
      mtnServiceId: "",
    },
  ]

  if (singleEventData?.data) {
    const { data } = singleEventData
    const isExistPass = passes?.data
      ?.filter(odp => odp?.mode === "LIVESTREAM")
      .sort((a, b) => a.id - b.id)

    const isExistOnDemandPass = passes?.data
      ?.filter(odp => odp?.mode === "ON_DEMAND")
      .sort((a, b) => a.id - b.id)

    let initData = {
      ...data,
      startAt: data?.startAt,
      endAt: data?.endAt,
      startDay: new Date(data?.startAt).getDate(),
      startMonth: new Date(data?.startAt).getMonth() + 1,
      rights: data?.rights,
      startYear: new Date(data?.startAt).getFullYear(),
      startTime: data?.startAt,
      vibes: data?.eventTag?.shortName,
      endDay: new Date(data?.endAt).getDate(),
      endMonth: new Date(data?.endAt).getMonth() + 1,
      endYear: new Date(data?.endAt).getFullYear(),
      endTime: data?.endAt,
      passes: isExistPass?.length === 0 ? $passes : isExistPass,
      onDemandPasses:
        isExistOnDemandPass?.length === 0
          ? $onDemandPasses
          : isExistOnDemandPass,
      image: data?.artist?.profileImageUrl,
      eventMode: data?.eventMode,
    }

    return {
      ...initData,
    }
  }

  let initForm = {
    title: "",
    startDay: new Date().getDate(),
    startMonth: new Date().getMonth() + 1,
    startYear: new Date().getFullYear(),
    eventMode: "LIVESTREAM",
    startTime: null,
    vibes: "africa_world",
    startAt: null,
    endDay: new Date().getDate() + 1,
    endMonth: new Date().getMonth() + 1,
    endYear: new Date().getFullYear(),
    endTime: "",
    endAt: null,
    rights: "global",
    location: "",
    venue: "",
    printUrl: "",
    printUrlImgType: "",
    chatUrl: "",
    musicUrl: "",
    bannerUrl: "",
    streamUrl: "",
    heroSectionTitle: "",
    heroSectionDescription: "",
    heroSectionUrls: [],
    videoCarouselTitle: "",
    videoCarouselDescription: "",
    currencyImageUrl: "",
    videoCarouselUrls: [],
    artistGalleryDescription: "",
    artistGalleryUrls: [],
    eventVisionHeaderFirst: "",
    eventVisionHeaderSecond: "",
    eventVisionHeaderDescription: "",
    eventVisionHeaderUrls: [],
    eventVisionSponsors: [],
    passes: $passes,
    onDemandPasses: $onDemandPasses,
    livestreamOnlyPasses: true,
    livestreamBackstagePasses: true,
    allInclusivePasses: true,
    isSprayMoneyEnabled: false,
    sprayMoneyCurrencyName: "",
    sprayMoneyCurrencyImage: "",
    sprayMoneyImageUrls: [],
    sprayMoneyTopSenderTitle: "",
    sprayMoneyTopSenderIcon: "",
    sprayMoneyAllowMTN: false,
    sprayMoneyAllowStripe: false,
    sprayMoneyAllowPaystack: false,
    userId: 1,
    artistId: "",
  }

  return initForm
}
