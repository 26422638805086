import { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { Box, TableRow } from '@mui/material';

import { LIMIT } from '../../constants';
import { getUserEmails, resendUserEmail } from '../../api/emails/emails';
import Table from '../table/Table';
import ActionList from '../actionsList/ActionList';
import resendEmailIcon from '../../assets/icons/resendEmailIcon.png';
import ellipsisIcon from '../../assets/icons/ellipsis.png';
import errorCircleIcon from '../../assets/icons/errorCircleIcon.svg';
import checkCircleIcon from '../../assets/icons/checkCircleIcon.svg';
import CustomTabelCell from '../common/CustomTebleCell/CustomTableCell';

const tableHeads = [
  { label: 'Email Type' },
  { label: 'Subject' },
  { label: 'Delivered' },
  { label: 'Received' },
  { label: 'Opened' },
  { label: ellipsisIcon, isIcon: true },
];

const Emails = ({ userId }) => {
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: LIMIT,
  });

  const {
    data,
  } = useQuery({
    queryKey: ['emails'],
    queryFn: () => getUserEmails({ page: pagination.pageIndex, limit: LIMIT, userId })
  });

  const {
    isSuccess: resentIsSuccess,
    isLoading: resendLoading,
    isError: resendError,
    error,
    data: resendData,
    mutate: resendMutate,
  } = useMutation(({ userId }) => resendUserEmail({ userId }))

  const handleResetEmail = (id) => resendMutate({userId: id});

  useEffect(() => {
    if (!resendLoading && resentIsSuccess) {
      enqueueSnackbar(resendData?.message, { variant: 'success' });
    }
  
    if (!resendLoading && resendError) {
      const errorData = error?.response?.data?.data;
  
      errorData?.forEach(({ field, message }) =>
        enqueueSnackbar(`${field ? field + ':' : ''}${message}`, { variant: 'error' })
      );
    }
  }, [resendLoading, resendError, resentIsSuccess, error?.response?.data?.data, resendData?.message]);
  

  return (
    <Box>
      <Table
        pagination={pagination}
        setPagination={setPagination}
        page={pagination?.pageIndex}
        meta={data?.meta}
        headers={tableHeads}
        error={error}
      >
        {
          data?.data.map((tableItem) => {
            return (
              <TableRow
                sx={{
                  background: '#FAFAFA 0% 0% no-repeat padding-box',
                  borderBottom: '7px solid white'
                }}
              >
                <CustomTabelCell>
                  {tableItem?.type}
                </CustomTabelCell>
                <CustomTabelCell >
                  {tableItem?.subject}
                </CustomTabelCell>
                <CustomTabelCell>
                  {tableItem?.delivered}
                </CustomTabelCell>
                <CustomTabelCell>
                  {tableItem?.received}
                </CustomTabelCell>
                <CustomTabelCell>
                  {
                    tableItem?.isOpened ? (
                      <Box>
                        <img src={checkCircleIcon} alt='' />

                      </Box>
                    ) : (
                      <Box>
                        <img src={errorCircleIcon} alt='error'     />         
                      </Box>
                    )
                  }
                </CustomTabelCell>
                <CustomTabelCell>
                  <ActionList actions={
                      [
                        { label: 'Resend Email', icon: resendEmailIcon, action: () => handleResetEmail(tableItem?.id) }
                      ]
                    } 
                  />
                </CustomTabelCell>
            </TableRow>
            )
          })
        }
      </Table>
    </Box>
  )
}

export default Emails;