import { useEffect, useState, useMemo } from "react"
import { useFormikContext } from "formik"
import { useDropzone } from "react-dropzone"
import dndIcon from "../../../assets/icons/drag-and-drop.png"

import "./StylesUploadEventPrint.scss"
import { Alert, CircularProgress } from "@mui/material"
import { useMutation } from "react-query"
import { uploadImage } from "../../../api/files"
import { enqueueSnackbar } from "notistack"

const thumbsContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  flexWrap: "wrap",
  width: "19rem",
  height: "19rem",
  border: "1px solid #707070",
  backgroundColor: "white",
  marginTop: 4,
  position: "relative",
  cursor: "pointer",
}

const thumb = {
  width: "100%",
  height: "100%",
}

const thumbInner = {
  height: "100%",
  width: "100%",
}

const img = {
  display: "block",
  height: "100%",
  width: "100%",
  backgroundSize: "cover",
}

const horizontalType = {
  width: "11rem",
  height: "6.4rem",
}

const verticalType = {
  width: "7.7rem",
  marginRight: "3.5rem",
  height: "9.6rem",
}

const blocksName = [
  "sponsor",
  "artistGallery",
  "heroSection",
  "videoCarouselUrls",
  "sprayMoney",
  "passes",
  "eventVisionHeaderUrls",
  "sprayMoneyImageUrls",
  "onDemandPasses",
  "streamPassVideoContentUrls",
  "artistCoverImageUrl",
  "blogCoverImageUrl",
  "contentCoverImageUrl"
]

const UploadEventPrint = ({
  label,
  thumbLabel,
  subText,
  subText2,
  type,
  blockName,
  fieldName,
  tmpPreview,
  setTmpPreview,
  index,
  item,
  mustBeLandscape, // Prop for landscape requirement
}) => {
  const [lastUploadFile, setLastUploadFile] = useState(null)
  const { setFieldValue } = useFormikContext()
  const [files, setFiles] = useState([])
  const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      accept: {
        "image/png": [".png"],
        "image/jpeg": [".jpeg"],
      },
    })

  const { mutate: mutateUploadImage, isLoading: isLoadingUploadImage } =
    useMutation(file => uploadImage(file))

  const thumbs = useMemo(() => {
    if (isLoadingUploadImage) {
      return <CircularProgress />
    }

    if (
      (!Object.keys(tmpPreview).length || !tmpPreview[fieldName]?.preview) &&
      (!item || typeof item !== "string")
    ) {
      return (
        <div className={"dropImageWrapper"}>
          <img src={dndIcon} alt={"drop"} />
        </div>
      )
    }

    if (
      (tmpPreview && Object.keys(tmpPreview).length) ||
      item?.content ||
      item
    ) {
      const currentImg = tmpPreview[fieldName]
      return (
        <div style={thumb}>
          <div style={thumbInner}>
            <img
              src={
                currentImg?.preview
                  ? currentImg?.preview
                  : item?.imgContent
                  ? item?.imgContent
                  : item
              }
              style={img}
              alt=""
            />
          </div>
        </div>
      )
    }
  }, [tmpPreview, isLoadingUploadImage, fieldName, item])

  useEffect(() => {
    if (lastUploadFile) {
      setTmpPreview(prevState => {
        return {
          ...prevState,
          [fieldName]: lastUploadFile,
        }
      })
    }
    // eslint-disable-next-line
  }, [lastUploadFile])

  useEffect(() => {
    ;(async () => {
      if (acceptedFiles.length) {
        const file = acceptedFiles[0]

        // New validation logic for landscape orientation and specific dimensions
        const img = new Image()
        img.src = URL.createObjectURL(file)
        img.onload = () => {
          const { width, height } = img
          const isLandscape = width > height
        
          // Validation should allow 520x240 dimensions and landscape orientation
        if (mustBeLandscape && !isLandscape) {
          enqueueSnackbar(
            "The uploaded image must be in landscape orientation and have dimensions of 520x240.",
            { variant: "error" }
          )
          return // Early return prevents further execution, including setting tmpPreview
        }

        setLastUploadFile(file) // Only set lastUploadFile if validation passes

          mutateUploadImage(file, {
            onSuccess: data => {
              if (blocksName.includes(blockName)) {
                if (blockName === "sponsor") {
                  return setFieldValue(
                    `eventVisionSponsors[${index}]`,
                    data?.data?.Location
                  )
                }

                if (blockName === "artistCoverImageUrl") {
                  setFieldValue(`coverImageUrl`, data?.data?.Location)
                  return
                }

                if (blockName === "blogCoverImageUrl") {
                  setFieldValue(`cover`, data?.data?.Location)
                  return
                }

                if (blockName === "contentCoverImageUrl") {
                  setFieldValue(`imageUrl`, data?.data?.Location)
                  return
                }

                if (blockName === "sprayMoneyImageUrls") {
                  return setFieldValue(
                    `sprayMoneyImageUrls[${index}]`,
                    data?.data?.Location
                  )
                }

                if (blockName === "eventVisionHeaderUrls") {
                  return setFieldValue(
                    `eventVisionHeaderUrls[${index}]`,
                    data?.data?.Location
                  )
                }

                if (blockName === "artistGallery") {
                  return setFieldValue(
                    `artistGalleryUrls[${index}]`,
                    data?.data?.Location
                  )
                }

                if (blockName === "heroSection") {
                  return setFieldValue(
                    `heroSectionUrls[${index}]`,
                    data?.data?.Location
                  )
                }

                if (blockName === "videoCarouselUrls") {
                  return setFieldValue(
                    `videoCarouselUrls[${index}].cover`,
                    data?.data?.Location
                  )
                }

                if (blockName === "sprayMoney") {
                  return setFieldValue(fieldName, data?.data?.Location)
                }

                if (blockName === "passes") {
                  return setFieldValue(fieldName, data?.data?.Location)
                }

                if (blockName === "onDemandPasses") {
                  setFieldValue(fieldName, data?.data?.Location)
                }
                if (blockName === "streamPassVideoContentUrls") {
                  setFieldValue(fieldName, data?.data?.Location)
                  return
                }
              }

              if (
                fieldName === "sprayMoneyCurrencyImage" ||
                fieldName === "sprayMoneyTopSenderIcon"
              ) {
                setFieldValue(fieldName, data?.data?.Location)
              }

              if (fieldName === "printUrl") {
                setFieldValue(fieldName, data?.data?.Location)
              }
            },
          })
        }
      }
    })()

    setFiles(
      acceptedFiles.map(file => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      })
    )

    return () => files.forEach(file => URL.revokeObjectURL(file.preview))
    // eslint-disable-next-line
  }, [acceptedFiles])

  const styledAside = () => {
    if (type === "horizontal") return { ...thumbsContainer, ...horizontalType }
    if (type === "vertical") return { ...thumbsContainer, ...verticalType }

    return {
      ...thumbsContainer,
      width: "19rem",
      height: "19rem",
    }
  }

  return (
    <div className="UploadEventPrint">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div>
          {thumbLabel && <label>{thumbLabel}</label>}
          <aside style={styledAside()} onClick={open}>
            {thumbs}
          </aside>
        </div>
      </div>
      <div className="container">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <p className="UploadEventPrint__label">{label}</p>
          <button type="button" onClick={open}>
            Upload File
          </button>
        </div>
        <aside>
          <p className="UploadEventPrint__subText">{subText}</p>
          <p className="UploadEventPrint__subText">{subText2}</p>
        </aside>
        {fileRejections.length ? (
          <Alert severity="error">File could not be added!</Alert>
        ) : null}
      </div>
    </div>
  )
}

export default UploadEventPrint
