import { useEffect, useState } from "react"
import { Field, Form, useFormikContext } from "formik"
import { ButtonGroup, Grid, Box } from "@mui/material"
import { useQuery } from "react-query"

import { getSinglePass } from "../../api/passes/index"
import { getEvents } from "../../api/events"
import { getUsers } from "../../api/user"
import { LIMIT } from "../../constants"
import Autocomplete from "../../components/common/autocomplete/Autocomplite"
import Input from "../../components/common/input/Input"
import Select from "../../components/common/select/Select"
import Button from "../../components/common/button/Button"
import useDebounce from "../../hooks/useDebounce"

const OrderReason = [
  { label: "Payment Method Failed", value: "PAYMENT_METHOD_FAILED" },
  { label: "Bank transfer issues", value: "BANK_TRANSFER_ISSUES" },
  { label: "Marketing Purposes", value: "MARKETING_PURPOSES" },
  { label: "Offline Payment", value: "OFFLINE_PAYMENT" },
]

const CreateStreamPassTransactionForm = ({
  setIsNotify,
  isSaveLoading,
  touched,
  errors,
  navigator,
}) => {
  const { setFieldValue } = useFormikContext()
  const [events, setEvents] = useState([])
  const [users, setUsers] = useState([])
  const [passTypes, setPassTypes] = useState([])
  const [page] = useState(1)
  const [eventsSearchTerm, setSearchTerm] = useState("")
  const [userSearchTerm, setUserSearchTerm] = useState("")
  const [eventId, setEventId] = useState()

  const debouncedSearchTerm = useDebounce(eventsSearchTerm, 300)
  const userDebouncedSearchTerm = useDebounce(userSearchTerm, 300)

  const {
    data: dataPasses,
    isLoading: isLoadingPasses,
    isError: isErrorPasses,
  } = useQuery(["getSinglePass", eventId], () => getSinglePass({ eventId }), {
    enabled: !!eventId,
  })

  const { isLoading, data, refetch } = useQuery({
    queryKey: ["events"],
    queryFn: () => getEvents({ page, limit: LIMIT, search: eventsSearchTerm }),
    retry: 0,
  })
  const {
    isLoading: isLoadingUser,
    data: userData,
    refetch: userRefetch,
  } = useQuery({
    queryKey: ["users"],
    queryFn: () => getUsers({ page, limit: LIMIT, search: userSearchTerm }),
    retry: 0,
  })

  const handleChengeEventAutocomplite = ({ label }) => setSearchTerm(label)
  const handleChengeUserAutocomplite = ({ label }) => setUserSearchTerm(label)

  useEffect(() => {
    const selectEvents = events?.find(
      ({ label }) => label === debouncedSearchTerm
    )

    if (selectEvents) {
      setEventId(selectEvents?.id)
      setFieldValue("liveStream", selectEvents?.id)
    }

    refetch()
  }, [debouncedSearchTerm, refetch, setFieldValue, events])

  useEffect(() => {
    const selectedUser = users?.find(
      ({ label }) => label === userDebouncedSearchTerm
    )

    setFieldValue("user", selectedUser?.id)
    userRefetch()
  }, [userDebouncedSearchTerm, setFieldValue, userRefetch, users])

  useEffect(() => {
    if (!isLoadingPasses && !isErrorPasses) {
      const uniqueLabels = new Set()
      const result = dataPasses?.data
        .map(pass => {
          return {
            label: pass?.type,
            value: pass?.id,
          }
        })
        .filter(
          ({ label }) => !uniqueLabels.has(label) && uniqueLabels.add(label)
        )
      setPassTypes(result)
    }
  }, [isLoadingPasses, isErrorPasses, dataPasses?.data])

  useEffect(() => {
    if (!isLoading && data?.data) {
      setEvents(() => {
        return data?.data.map(event => {
          return {
            ...event,
            label: event.title,
          }
        })
      })
    }
  }, [isLoading, data?.data])

  useEffect(() => {
    if (!isLoadingUser && userData?.data) {
      const preparedUsers = userData?.data.map(user => {
        return {
          id: user.id,
          label: user?.username || user?.email || "",
        }
      })

      setUsers(preparedUsers)
    }
  }, [isLoadingUser, userData?.data])

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              gap: 2,
            }}
          >
            <Field
              label="Choose User"
              placeholder="Search by Email"
              name="user"
              component={Autocomplete}
              onChange={handleChengeUserAutocomplite}
              options={users.length ? users : []}
              error={errors?.user && touched?.user}
              helperText={errors?.user}
            />
            <Field
              label="Choose Stream Pass Type"
              name="streamPassType"
              component={Select}
              options={passTypes}
              error={errors?.streamPassType && touched?.streamPassType}
              helperText={errors?.streamPassType}
            />
            <Field
              label="Choose Reason"
              name="reason"
              component={Select}
              options={OrderReason}
              error={errors?.reason && touched?.reason}
              helperText={errors?.reason}
            />
            <Button
              type="button"
              onClick={() => {
                navigator("/transactions")
              }}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              gap: 2,
            }}
          >
            <Field
              label="Select Livestream"
              name="liveStream"
              component={Autocomplete}
              onChange={handleChengeEventAutocomplite}
              options={events ? events : []}
            />
            <Field
              label="Enter Quantity"
              name="enterQuantity"
              placeholder="Enter Quantity"
              component={Input}
              error={errors?.enterQuantity && touched?.enterQuantity}
              helperText={errors?.enterQuantity}
            />
            <ButtonGroup
              sx={{
                gap: "3rem",
                marginTop: "auto",
              }}
            >
              <Button
                onClick={() => {
                  setIsNotify(false)
                }}
                type="submit"
                isLoading={isSaveLoading}
              >
                Save & Exit
              </Button>
              <Button
                onClick={() => setIsNotify(true)}
                type="submit"
                isModal
                isLoading={isSaveLoading}
              >
                Save & Notify
              </Button>
            </ButtonGroup>
          </Box>
        </Grid>
      </Grid>
    </Form>
  )
}

export default CreateStreamPassTransactionForm
