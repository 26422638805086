import { enqueueSnackbar } from "notistack"
import { Formik, Field, Form } from "formik"
import { Grid, Box, Typography } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { useMutation } from "react-query"
import { useState } from "react"

import { loginValidate } from "../../validates/loginValidate"
import { login } from "../../api/auth"
import Input from "../../components/common/input/Input"
import Button from "../../components/common/button/Button"

const LoginForm = () => {
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate()
  const {  mutate } =
    useMutation(login)

  const onSubmitHandler = (
    { email, password },
    mutateFunction,
    enqueueSnackbarFunction,
    navigateFunction
  ) => {
    setLoading(true)
    const authData = `{"email":"${email}", "password": "${password}"}`

    mutateFunction(authData, {
      onSuccess: data => {
       setLoading(false)
        localStorage.setItem("user", JSON.stringify(data?.data?.[0]))
        localStorage.setItem("token", JSON.stringify(data?.tokens?.accessToken))
        navigate("/dashboard")
      },
      onError: err => {
       setLoading(false)
        const errorData = err?.response?.data?.message
        if (Array.isArray(errorData)) {
          enqueueSnackbar(`${errorData[0]}`, { variant: "error" })
        } else {
          enqueueSnackbar(`${errorData}`, { variant: "error" })
        }
      },
    })
  }

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={values =>
        onSubmitHandler(values, mutate, enqueueSnackbar, navigate)
      }
      validationSchema={loginValidate}
    >
      {({ errors, touched }) => {
        return (
          <Form>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  gap: "5rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Typography
                    sx={{ paddingBottom: "1.5rem" }}
                    variant="h2"
                    component="h2"
                  >
                    Livestream Platform
                  </Typography>
                  <Typography variant="h4" component="h4">
                    Log in with your user details to continue
                  </Typography>
                </Box>
                <Box
                  sx={{
                    gap: "2rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Field
                    component={Input}
                    label="Enter Email Address/Username"
                    name="email"
                    placeholder="Email Address"
                    error={errors?.email && touched?.email}
                    helperText={errors?.email}
                  />
                  <Field
                    component={Input}
                    label="Enter Password"
                    name="password"
                    placeholder="Password"
                    type="password"
                    error={errors?.password && touched?.password}
                    helperText={errors?.password}
                  />
                </Box>
                <Grid container direction="column" spacing={5}>
                  <Grid item>
                    <Button type="submit" isLoading={loading}>
                      ConfirmDetails
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Link
                      to="/forgotPassword"
                      style={{
                        fontSize: "1.7rem",
                        textDecoration: "underline",
                        display: "inline-flex",
                        color: "#000",
                        fontFamily: "Baguede",
                        fontWeight: "600",
                      }}
                    >
                      Forgot password
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default LoginForm
