import { useEffect, useMemo, useState } from 'react';
import { Form, Field, useFormikContext } from 'formik';
import { FormControlLabel, Checkbox as MatrialCheckbox, Grid, RadioGroup, FormLabel } from '@mui/material';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { getSigleUserGroup } from '../../api/userGropup';
import CheckBox from '../../components/common/chexbox/CheckBox';
import Input from '../../components/common/input/Input';
import RadioButton from '../../components/common/radioButton/RadioButton';
import Button from '../../components/common/button/Button';

const AddEditAdminGroupForm = ({ permissions, fields, isNewGroup, isLoading }) => {
  const navigator = useNavigate();
  const { 
    values,
    setValues,
   } = useFormikContext();
  const [isSelectAll, setIsSelectAll] = useState(false);

  const {
    isLoading: isLodingSingleUserGroup,
    data: isDataSigleUserGroup,
    mutate: mutateSigleUserGroup,
  } = useMutation(({ id }) => getSigleUserGroup({ id }));

  useEffect(() => {
    const params = new URL(window.location).searchParams;
    const id = params.get('groupId');

    if (id) mutateSigleUserGroup({ id });
  }, [mutateSigleUserGroup])



  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setIsSelectAll(checked);

    const names = Object.keys(fields).map(key => key);
    const tmpObj = {};

    names.forEach((name) => {
        tmpObj[name] = checked;
    })

    setValues(Object.assign(values, tmpObj))

  };

  useEffect(() => {
    if (!isLodingSingleUserGroup && isDataSigleUserGroup?.data) {
      const userPermision = isDataSigleUserGroup?.data?.permissions.map(({ name }) => name);
      const permissionName = Object.keys(fields).map(name => name)
      const tmpObj = {};

      if (userPermision?.length && permissionName.length) {
        userPermision?.forEach((name) => tmpObj[name] = permissionName.includes(name));
      }

      setValues({
        ...values,
        ...tmpObj,
        name: isDataSigleUserGroup?.data?.name,
        identifier: isDataSigleUserGroup?.data?.identifier,
        status: isDataSigleUserGroup?.data?.status
      });
    }

  }, [isDataSigleUserGroup, isLodingSingleUserGroup, fields, setValues, values])

  const form = useMemo(() => {
    const selectAll = Object.entries(values).every(([key, value]) => {
      if(typeof value !== 'boolean') return true;
    
      return value;
    });

    setIsSelectAll(selectAll);

    return (
        Object.entries(permissions).map(([key, value]) => (
          <Grid key={key} item>
            <FormLabel key={key} component='legend'>{key}</FormLabel>
            {              
              value.map((item) => {
                return (
                  <FormControlLabel
                    key={item?.name}
                    control={
                      <Field
                        component={CheckBox}
                        name={item?.name}
                        checked={values[item?.name]}
                      />
                    }
                    label={item?.label}
                  />
                )
              })
            }
          </Grid>
        ))
    )
  }, [ values, permissions])

  return (
    <Form>
      <Grid container flexDirection='column'>
      <Grid container spacing={5}>
        <Grid xs={3} item>
          <Field
            name='name'
            component={Input}
            label='Usergroup Name *'
            placeholder='Enter Name'
          />
        </Grid>
        <Grid xs={3} item>
          <Field
            name='identifier'
            component={Input}
            label='Identifier *'
            placeholder='Enter Identifier'
          />
        </Grid>
      </Grid>
      <Grid container xs={6} justifyContent='end'>
          <RadioGroup
            name="radio-buttons-group"
            row
          >
            <Field
              component={RadioButton}
              name='status'
              type='radio'
              value='ACTIVE'
              label='Active'
            />
            <Field
              name='status'
              component={RadioButton}
              type='radio'
              value='INACTIVE'
              label='Inactive'
            />
          </RadioGroup>
        </Grid>
      </Grid>
      <Grid item>
        <FormLabel component="legend">All</FormLabel>
        <FormControlLabel
          control={
            <MatrialCheckbox
              name='all'
              checked={isSelectAll}
              onChange={handleSelectAll}
            />
          }
          label='All'
        />
      </Grid>
      {form}
      <Grid item sx={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10rem',
      }}>
        <Button type='button' onClick={() => navigator('/adminGroups')}>Cancel</Button>
        <Button type='submit' isLoading={isLoading} isModal>{isNewGroup ? 'Edit Group' : 'Create Group'}</Button>
      </Grid>
    </Form>
  )
}

export default AddEditAdminGroupForm;