import { useQuery, useMutation } from 'react-query';
import { useState, useEffect } from 'react';
import { Box, TableRow } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import { LIMIT } from '../../constants';
import { resendUserEmail } from '../../api/emails/emails';
import { getUserGuests } from '../../api/guests';
import { convertDate } from '../../utils/convertDate';
import ActionList from '../actionsList/ActionList';
import CustomTabelCell from '../common/CustomTebleCell/CustomTableCell';
import ellipsisIcon from '../../assets/icons/ellipsis.png';
import checkCircleIcon from '../../assets/icons/checkCircleIcon.svg';
import errorCircleIcon from '../../assets/icons/errorCircleIcon.svg';
import resendEmailIcon from '../../assets/icons/resendEmailIcon.png';
import Table from '../table/Table';

const tableHeads = [
  { label: 'Guest Email address' },
  { label: 'Livestream Event' },
  { label: 'Delivered' },
  { label: 'User Account' },
  { label: ellipsisIcon, isIcon: true },
];

const  Guests = ({ userId }) => {
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: LIMIT,
  });

	const {
		data,
		refetch,
    error,
	} = useQuery({
    retry: 0,
		queryKey: ['guests'],
		queryFn: () => getUserGuests({ page: pagination.pageIndex, limit: LIMIT, userId })
	})


  const {
    isLoading: isLoadingResend,
    isError: isErrorResend,
    isSuccess: isResendSuccess,
    mutate: mutateResend,
    error: errorResend,
    data: resendData,
  } = useMutation(({ emailId }) => resendUserEmail({ userId: emailId }));

  const handleResend = ({ emailId }) => {
    mutateResend({ emailId });
  }

  useEffect(() => {
    if (!isLoadingResend && isResendSuccess) {
      enqueueSnackbar(resendData?.message, { variant: 'success' });
    }

    if (!isLoadingResend && isErrorResend) {
      const erorrData = errorResend?.response?.data?.data;

      erorrData?.forEach(({ field, message }) => enqueueSnackbar(`${field ? field + ':' : ''}${message}`, { variant: 'error' }));
    }
  }, [isLoadingResend, isErrorResend, isResendSuccess,  errorResend?.response?.data?.data, resendData?.message])

	useEffect(() => {
		refetch();
	}, [pagination.pageIndex, refetch])

	return (
    <Box>
      <Table
        pagination={pagination}
        setPagination={setPagination}
        page={pagination?.pageIndex}
        meta={data?.meta}
        headers={tableHeads}
        error={error}
      >
        {
          data?.data.map((tableItem) => {
            return (
              <TableRow
                style={{fontFamily: "Baguede"}}
                
                sx={{
                  background: '#FAFAFA 0% 0% no-repeat padding-box',
                  borderBottom: '7px solid white'
                }}
              >
                <CustomTabelCell>
                  {tableItem?.email}
                </CustomTabelCell>
                <CustomTabelCell>
                  {tableItem?.event}
                </CustomTabelCell>
                <CustomTabelCell>
                  {convertDate(tableItem?.sharedAt, '-')}
                </CustomTabelCell>
                <CustomTabelCell>
                  {
                    tableItem?.userAccount === 'ACTIVE' ? (
                    <Box>
                      <img src={checkCircleIcon} alt='circle'/>
                    </Box>
                    ) : (
                    <Box>
                      <img src={errorCircleIcon} alt='circle'/>
                    </Box>
                    )
                  }
                </CustomTabelCell>
                <CustomTabelCell>
                <ActionList
                  actions={[
                    { label: 'Resend Email', icon: resendEmailIcon, action: () => handleResend({ emailId: tableItem?.emailId }) },
                  ]}
                />
                </CustomTabelCell>
              </TableRow>
            )
          })
        }
      </Table>
    </Box>
	)
}

export default  Guests;