import { fetchClient } from "../axiosinterceptor";

export const getArtist = async ({ s }) => {
  const { data } = await fetchClient().get(
    `artists/${s.length > 0 ? "?s=" + s : ""}`
  );
  return data;
};

export const getAllArtist = async ({limit, page, search}) => {
  const { data } = await fetchClient().get(
    `artists?limit=${limit}&page=${page}`,
    {
      params: {
        s: search?.length ? search : null,
      },
    }
  );
  return data;
};

export const deactivateArtist = async ({ artistId }) => {
  const { data } = await fetchClient().delete(`artists/${artistId}`);

  return data;
};

export const getArtistById = async ({ artistId }) => {
  const { data } = await fetchClient().get(`/artists/${artistId}`);

  return data;
};

export const createArtist = async ({ value }) => {
  console.log({value})
  const { data } = await fetchClient().post("/artists", {
    ...value,
  });

  return data;
};

