import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { TableRow } from '@mui/material';

import { validatePending } from '../../api/orders';
import { getUserTransactions } from '../../api/transactions';
import { LIMIT } from '../../constants';
import ActionList from '../actionsList/ActionList';
import Table from '../table/Table';
import viewOrderIcon from '../../assets/icons/viewOrderIcon.png';
import validatePendingIcon from '../../assets/icons/validatePendingIcon.png';
import transactionIcon from '../../assets/icons/transactionIcon.png';
import deliveryIcon from '../../assets/icons/deliveryIcon.png';
import bankTransferIcon from '../../assets/icons/bankTransferIcon.png';
import TransactionInformation from '../transactionInformation/TransactionInformation';
import ProcessRefundModal from '../modals/processRefundModal/ProcessRefundModal';
import CompeteOrderModal from '../modals/completeOrderModal/CompeteOrderModal';
import ReopenOrderModal from '../modals/reopenOrderModal/ReopenOrderModal';
import ellipsisIcon from '../../assets/icons/ellipsis.png';
import ValidatePendingModal from '../modals/validatePendingModal/ValidatePendingModal';
import CustomTabelCell from '../common/CustomTebleCell/CustomTableCell';
import {getOrderStatus} from "../../utils/getOrderStatus";

const orderDataTrasform = (orders) => {
  if (orders) {
    return orders.map((order) => {
      return {
        orderId: order?.id,
        status: order?.status,
        items: order?.orderItem?.length,
        orderItem: order?.orderItem,
        type: 'mockData',
        price: 'mockData',
        data: 'mock data',
      }
    });
  }

  return [];
}


const getTransactionById = (transactionList, transactionId) => {
  if (transactionList && Array.isArray(transactionList)) {
    const selectOrder = transactionList.find(({ id }) => id === transactionId);

    if (selectOrder) return selectOrder;
  }

  return {};
}

const Transactions = ({ userId }) => {

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: LIMIT,
  });
  const [transactions, setTransactions] = useState([]);

  const [selectTransaction, setSelectTransaction] = useState({});
  const [isShowTransactionInfo, setIsShowTransactionInfo] = useState(false);
  const [isShowProcessRefundModal, setIsShowProcessRefundModal] = useState(false);
  const [isShowCompleteOrderModal, setIsShowCompleteOrderModal] = useState(false);
  const [isShowReopenOrderModal, setIsShowReopenOrderModal] = useState(false);
  const [isShowValidatePendingModal, setIsShowValidatePendingModal] = useState(false);

  const handleCloseTransactionInfo = () => setIsShowTransactionInfo(() => false);
  const handleCloseProcessRefundModal = () => setIsShowProcessRefundModal(() => false);
  const handleCloseCompleteOrderModal = () => setIsShowCompleteOrderModal(() => false);
  const handleCloseReopenOrderModal = () => setIsShowReopenOrderModal(() => false);
  const handleCloseValidatePendingModal = () => setIsShowValidatePendingModal(() => false);

  const {
    data: userTransactionData,
    error,
    refetch,
  } = useQuery({
    queryKey: ['transaction'],
    queryFn: () => getUserTransactions({ page: pagination.pageIndex, limit: LIMIT, userId: userId })
  });

useMutation(({ transactionId }) => validatePending({ transactionId }))

  const handleOpenReopenOrderModal = (transactionId) => {
    const { data } = userTransactionData;

    const transaction = getTransactionById(data, transactionId);

    setSelectTransaction(transaction);
    setIsShowReopenOrderModal(() => true)
  };

  const handleOpenCompleteOrderModal = (transactionId) => {
    const { data } = userTransactionData;

    const transaction = getTransactionById(data, transactionId);

    setSelectTransaction(transaction);
    setIsShowCompleteOrderModal(() => true);
  };

  const handleShowTransactionInfo = (transactionId) => {
    setIsShowTransactionInfo(() => true);

    const { data } = userTransactionData;

    const transaction = getTransactionById(data, transactionId);

    setSelectTransaction(transaction);
  };

  const handleShowValidatePending= (transactionId) => {
    setIsShowValidatePendingModal(() => true);

    const { data } = userTransactionData;

    const transaction = getTransactionById(data, transactionId);

    setSelectTransaction(transaction);
  };

  const handleOpenProcessRefundModal = (transactionId) => {
    setIsShowProcessRefundModal(() => true);
    const { data } = userTransactionData;

    const transaction = getTransactionById(data, transactionId);

    setSelectTransaction(transaction);
  }

  useEffect(() => {
    const result = orderDataTrasform(userTransactionData?.data);
    setTransactions(result);
  }, [userTransactionData])

  const tableHeads = [
    { label: 'Order ID' },
    { label: 'Price' },
    { label: 'Items' },
    { label: 'Type' },
    { label: 'Date' },
    { label: 'Status' },
    { label: ellipsisIcon, isIcon: true },
  ]

  return (
    <div>
      {
        isShowValidatePendingModal &&
          <ValidatePendingModal
            selectTransactionId={selectTransaction?.id}
            handleClose={handleCloseValidatePendingModal}
            refetch={refetch}
          />
      }
      {
        isShowReopenOrderModal &&
          <ReopenOrderModal
            selectTransactionId={selectTransaction?.id}
            handleClose={handleCloseReopenOrderModal}
            refetch={refetch}
          />
      }
      {
        isShowCompleteOrderModal &&
          <CompeteOrderModal
            handleClose={handleCloseCompleteOrderModal}
            selectTransactionId={selectTransaction?.id}
            refetch={refetch}
          />
      }
      {
        isShowProcessRefundModal &&
          <ProcessRefundModal
            handleCloseProcessRefundModal={handleCloseProcessRefundModal}
            selectTransactionId={selectTransaction?.id}
            refetch={refetch}
          />
      }
      {
        isShowTransactionInfo &&
          <TransactionInformation
            onClose={handleCloseTransactionInfo}
            selectTransaction={selectTransaction}
          />
      }
      <Table
        pagination={pagination}
        setPagination={setPagination}
        page={pagination?.pageIndex}
        meta={userTransactionData?.meta}
        headers={tableHeads}
        error={error}
      >
        {
          transactions?.map((tableItem) => {
            return (
              <TableRow
                sx={{
                  background: '#FAFAFA 0% 0% no-repeat padding-box',
                  borderBottom: '7px solid white'
                }}
              >
                <CustomTabelCell>
                  {tableItem?.orderId}
                </CustomTabelCell>
                <CustomTabelCell>
                  {tableItem?.price}
                </CustomTabelCell>
                <CustomTabelCell>
                  {tableItem?.items}
                </CustomTabelCell>
                <CustomTabelCell>
                  {tableItem?.type}
                </CustomTabelCell>
                <CustomTabelCell>
                  {tableItem?.data}
                </CustomTabelCell>
                <CustomTabelCell>
                  {getOrderStatus(tableItem?.status)}
                </CustomTabelCell>
                <CustomTabelCell>
                  <ActionList
                    actions={[
                      { label: 'View Order', icon: viewOrderIcon, action: () => handleShowTransactionInfo(tableItem?.orderId), status: tableItem.status },
                      { label: 'Refund Order', icon: validatePendingIcon, action: () => handleOpenProcessRefundModal(tableItem?.orderId), status: tableItem.status },
                      { label: 'Mark as Complete', icon: transactionIcon, action: () => handleOpenCompleteOrderModal(tableItem?.orderId), status: tableItem.status },
                      { label: 'Reopen Order', icon: deliveryIcon, action: () => handleOpenReopenOrderModal(tableItem?.orderId), status: tableItem.status },
                      { label: 'Validate Pending', icon: bankTransferIcon, action: () => handleShowValidatePending(tableItem?.orderId), status: tableItem.status },
                    ]}
                  />
                </CustomTabelCell>
              </TableRow>
            )
          })
        }
      </Table>
    </div>
  )
}

export default Transactions;