import "./accordion-styles.scss";
import ionicIcon from "../../assets/icons/ionicIcon.png";
import checkCircleIcon from "../../assets/icons/checkCircleIcon.svg";
import errorCircleIcon from "../../assets/icons/errorCircleIcon.svg";
import { useState } from "react";

export const Accordion = ({
  children,
  headerTitle,
  error,
  noBorderBottom,
  status,
  itemPadding,
}) => {
  const [showContent, setShowContent] = useState(false);
  const handleShowContent = () => {
    setShowContent((prev) => !prev);
  };
  return (
    <div className="accor-wrap">
      <div
        className={noBorderBottom ? "noBorderBottom" : "accordion-item"}
        style={{ padding: itemPadding ? "0 4rem" : "" }}
      >
        <div className="accordion-header" onClick={handleShowContent}>
          <h5 className="title">{headerTitle}</h5>
          <div className="actions">
            <img
              className="arrow"
              src={ionicIcon}
              alt="..."
            />
            {status && (
              <div className="status">
                {error && (
                  <img className="icon" src={errorCircleIcon} alt="..." />
                )}
                {!error && (
                  <img className="icon" src={checkCircleIcon} alt="..." />
                )}
              </div>
            )}
          </div>
        </div>
        {showContent && <>{children}</>}
      </div>
    </div>
  );
};
