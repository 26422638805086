import { Box, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { Field, Form, useFormikContext } from "formik";
import FormWrapper from "../../components/common/formWrapper/FormWrapper";
import Input from "../../components/common/input/Input";
import Button from "../../components/common/button/Button";
import CountryCodeInput from "../../components/countryCodeInput/CountryCodeInput";
import "./StylesCreateArtistForm.scss";

export const CreateArtistForm = ({ isNewArtist = true, errors, touched, isLoading }) => {
  const { submitForm, validateForm } = useFormikContext();

  const handleSubmit = async () => {
    const validationErrors = await validateForm();
    if (Object.keys(validationErrors).length > 0) {
      const firstErrorKey = Object.keys(validationErrors)[0];
      enqueueSnackbar(`${firstErrorKey}: ${validationErrors[firstErrorKey]}`, { variant: "error" });
    } else {
      await submitForm();
    }
  };

  return (
    <FormWrapper>
      <Form>
        <Box className="bio-section">
          <Typography component={"h3"}>Artist Bio</Typography>
          <Field component={Input} name="fullName" label="Full Name" />
          <Box className="bio">
            <Field
              component={Input}
              label="Record Label"
              name="recordLabel"
              id="recordLabel"
            />
            <Field component={Input} name="stageName" label="Stage Name" />
          </Box>
          <Field
            component={Input}
            multiline
            rows={6}
            name="biography"
            label="Biography"
          />
          <Field
            component={Input}
            name="videoUrl"
            label="Artist Short Stream"
          />
        </Box>
        <Box className="contact-section">
          <Typography component={"h3"}>Contact Information</Typography>
          <Box className="contacts">
            <Field
              component={Input}
              label="Email"
              name="email"
            />
            <Field
              component={Input}
              label="Website"
              name="website"
            />
            <Box
              sx={{
                display: "flex",
                gap: "2rem",
              }}
            >
              <Box
                sx={{
                  width: "30%",
                }}
              >
                <Field
                  component={CountryCodeInput}
                  label="Country Code"
                  name="countryCode"
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Field
                  component={Input}
                  label="Phone Number"
                  name="phoneNumber"
                  type="number"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="social-section">
          <Typography component={"h3"}>Social Media Handles</Typography>
          <Box className="socials">
            <Field component={Input} label="Facebook" name="facebookUrl" />
            <Field component={Input} label="Twitter" name="twitterUrl" />
            <Field component={Input} label="Instagram" name="instagramUrl" />
          </Box>
        </Box>
        <Box
          className="test"
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button type="button" isLoading={isLoading} isModal onClick={handleSubmit}>
            {isNewArtist ? "Save to Platform" : "Save"}
          </Button>
        </Box>
      </Form>
    </FormWrapper>
  );
};
