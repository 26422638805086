import * as Yup from "yup";

export const createBlogValidate = Yup.object().shape({
  title: Yup.string().required("This field in required!"),
  category: Yup.string()
      .oneOf(["technology", "entertainment", "war"])
      .required("This field is required!"),
  description: Yup.string().required("This field in required!"),
  cover: Yup.string().nullable().url(),
  videoUrl: Yup.string()
  .required("This field is required!")
  .url("Invalid URL format")
  .test(
    'is-https',
    'URL must start with https://',
    value => value && value.startsWith('https://')
  ),
  content: Yup.string().required("This field in required!"),
  tags:Yup.array().of(Yup.string().required('Tag Array must not be a empty')),
});
