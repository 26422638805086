import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { enqueueSnackbar } from 'notistack'

import Modal from '../../common/modal/Modal';
import Button from '../../common/button/Button';
import './StylesDeactivateContentModal.scss';
import { deactivateContent } from '../../../api/contents/contents';

const DeactivateContentModal = ({ handleClose, contentId, refetch }) => {
  const {
    isLoading,
    isError,
    isSuccess,
    mutate
  } = useMutation(() => deactivateContent({contentId}));

  const handleDeactivate = () => mutate();

  useEffect(() => {
    if (!isError && !isLoading && isSuccess) {
      enqueueSnackbar('Event deactivated', { variant: 'success' });
      refetch();
      handleClose();
    }

    if(!isLoading && isError) {
      enqueueSnackbar('Issues with event deactivation', { variant: 'error' });
      handleClose();
    }
  }, [isLoading, isError, handleClose, isSuccess, refetch])

  return (
    <Modal
      title='Deactivate Content'
      onClose={handleClose}
    >
      <div className='DeactivateContentModal'>
        <span>
          Please confirm that you would like to mark this content as deactivated.
        </span>
        <Button
          isModal
          isLoading={isLoading}
          onClick={handleDeactivate}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

export default DeactivateContentModal;