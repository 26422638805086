import { Routes, Route, Navigate } from "react-router-dom"
import { SnackbarProvider } from "notistack"

import ForgotPasswordPage from "./pages/forgotPassword/ForgotPasswordPage"
import ResetPasswordPage from "./pages/resetPassword/ResetPasswordPage"
import LoginPage from "./pages/login/LoginPage"
import DashboardLayout from "./layouts/dashboard/DashboardLayout"
import DashboardPage from "./pages/dashboard/DashboardPage"
import UserInformationPage from "./pages/userInformation/UserInformationPage"
import LiveStreams from "./pages/liveStreams/LiveStreams"
import EmailsPage from "./pages/emails/EmailsPage"
import EditEmailPage from "./pages/emails/editEmail/EditEmailPage"
import AdminsPage from "./pages/admins/AdminsPage"
import CreateNewAdminPage from "./pages/admins/CreateNewAdminPage"
import TransactionsPage from "./pages/transactions/Transactions"
import CreateTransaction from "./pages/transactions/CreateTransaction"
import AdminsGroupsPage from "./pages/admins/AdminsGroups"
import CreateEditNewAdminGroupPage from "./pages/admins/CreateEditNewAdminGroupPage"
import LiveStreamInfoPage from "./pages/liveStreams/LiveStreamInfoPage"
import { AuthContext } from "./AuthContext"
import { useState } from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import { Theme } from "./theme"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import CreateBlog from "./pages/blogs/CreateBlog"
import Blogs from "./pages/blogs/Blogss"
import ArtistInformationPage from "./pages/artistInformation/ArtistInformationPage"
import Artists from "./pages/artistInformation/Artists"
import FeaturedContent from "./pages/featuredContent/featuredContent"
import ManageFeaturedContent from "./pages/featuredContent/manageFeaturedContent"
const App = () => {
  const [isAuth, setIsAuth] = useState(false)

  return (
    <ThemeProvider theme={Theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div style={{ height: "100%" }}>
          <AuthContext.Provider value={{ isAuth, setIsAuth }}>
            <SnackbarProvider>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="/forgotPassword"
                  element={<ForgotPasswordPage />}
                />
                <Route path="/v2/resetPassword" element={<ResetPasswordPage />} />
                <Route element={<DashboardLayout />}>
                  <Route path="/dashboard" element={<DashboardPage />} />
                  <Route
                    path="/userInformation"
                    element={<UserInformationPage />}
                  />
                  <Route path="/createArtist" element={<ArtistInformationPage />} />
                  <Route path="/artists" element={<Artists />} />
                  <Route
                    path="/createNewLiveStream"
                    index
                    element={<LiveStreamInfoPage />}
                  />
                  <Route path="/liveStream" index element={<LiveStreams />} />
                  <Route path="/featured"  element={<FeaturedContent />} />
                  <Route path="/createContent"  element={<ManageFeaturedContent />} />
                  <Route path="/comms" element={<EmailsPage />} />
                  <Route path="/blogs" element={<Blogs />} />
                  <Route path="/createBlog" element={<CreateBlog />} />
                  <Route path="/createEmail" element={<EditEmailPage />} />
                  <Route path="/admins" element={<AdminsPage />} />
                  <Route path="/adminInfo" element={<CreateNewAdminPage />} />
                  <Route path="/adminGroups" element={<AdminsGroupsPage />} />
                  <Route
                    path="/createEditAdminGroup"
                    element={<CreateEditNewAdminGroupPage />}
                  />
                  <Route path="/transactions" element={<TransactionsPage />} />
                  <Route
                    path="/createTransaction"
                    element={<CreateTransaction />}
                  />
                  <Route path="*" element={<Navigate to="/login" />} />
                </Route>
              </Routes>
            </SnackbarProvider>
          </AuthContext.Provider>
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
