import { fetchClient } from '../axiosinterceptor';

export const getUserGroups = async ({ page, limit }) => {
  const { data } = await fetchClient().get(`user-groups/limit/${limit}/page/${page}`);
  return data;
};

export const createNewUserGroups = async (values) => {
  const { data } = await fetchClient().post('user-groups', {
    ...values,
  });

  return data;
}

export const deleteUserGroup = async ({ id }) => {
  const { data } = await fetchClient().delete(`user-groups/${id}`);

  return data;
}

export const getSigleUserGroup = async ({ id }) => {
  const { data } = await fetchClient().get(`user-groups/${id}`);

  return data;
}

export const updateUserGroup = async ({ id, identifier, name, permissions,status  }) => {
  const { data } = await fetchClient().patch(`user-groups/update/${id}`, {
    identifier,
    name,
    permissions,
    status
  });

  return data;
}