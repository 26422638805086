import React, { useState } from "react";
import "./StylesTags.scss";

export const Tags = ({ initialTags = [], onChange }) => {
  const [tags, setTags] = useState(initialTags);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      addTag(inputValue.trim());
      setInputValue("");
    }
  };

  const addTag = (tag) => {
    if (!tags.includes(tag)) {
      const newTags = [...tags, tag];
      setTags(newTags);
      onChange(newTags);
    }
  };

  const removeTag = (index) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
    onChange(newTags);
  };

  return (
    <div className="tag-wrap">
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyPress={handleInputKeyPress}
        placeholder="Add a tag for blog..."
      />
      <div className="tag-list">
        {tags.map((tag, index) => (
          <span
            key={index}
            style={{
              margin: "4px",
              padding: "4px",
              border: "1px solid #ccc",
              backgroundColor:"#000",
              borderRadius: "4px",
              color: "#fff",
              textTransform: "capitalize"
            }}
          >
            {tag}
            <button
              type="button"
              onClick={() => removeTag(index)}
              style={{
                marginLeft: "4px",
                border: "none",
                textTransform: "capitalize",
                color: "#fff",
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </span>
        ))}
      </div>
    </div>
  );
};

export default Tags;
