import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Box, TableRow } from "@mui/material";
import { enqueueSnackbar } from "notistack";

import { getEvents } from "../../api/events";
import {
  deleteEmailTemplate,
  getEmailTemplates,
  duplicateEmailTemplate,
} from "../../api/emails/emails";
import { LIMIT } from "../../constants";
import useDebounce from "../../hooks/useDebounce";
import Page from "../../components/common/page/Page";
import Button from "../../components/common/button/Button";
import Table from "../../components/table/Table";
import ellipsisIcon from "../../assets/icons/ellipsis.png";
import ActionList from "../../components/actionsList/ActionList";
import CustomTabelCell from "../../components/common/CustomTebleCell/CustomTableCell";
import DeleteEmailIcon from "../../assets/icons/bankTransferIcon.png";
import DuplicateEmailIcon from "../../assets/icons/transactionIcon.png";
import EditEmail from "../../assets/icons/viewOrderIcon.png";
import checkCircleIcon from "../../assets/icons/checkCircleIcon.svg";
import errorCircleIcon from "../../assets/icons/errorCircleIcon.svg";
import TableFilter from "../../components/tableFilter/TableFIlter";

const tableHeads = [
  { label: "Email Template" },
  { label: "Subject" },
  { label: "From" },
  { label: "Type" },
  { label: "Status" },
  { label: ellipsisIcon, isIcon: true },
];

const filterUserStatus = [
  { label: "Email type", value: "placeholder" },
  { label: "System Emails", value: "SYSTEM" },
  { label: "Event Emails", value: "EVENT" },
  { label: "Marketing Emails", value: "MARKETING" },
  { label: "Transactional Emails", value: "TRANSACTION" },
  { label: "User Emails", value: "USER" },
];

const EmailsPage = () => {
  const navigator = useNavigate();
  const [filters, setFilters] = useState();
  const [searchEvent, setSearchEvent] = useState("");
  const [events, setEvents] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: LIMIT,
  });
  const debouncedFilters = useDebounce(filters, 300);
  const debouncedSearchEvent = useDebounce(searchEvent, 300);

  const { data, refetch, error } = useQuery({
    retry: 0,
    queryKey: ["emailTamplates"],
    queryFn: () =>
      getEmailTemplates({
        page: pagination.pageIndex,
        limit: LIMIT,
        type: filters?.type,
        search: filters?.search,
        eventId: filters?.eventId,
      }),
  });

  const {
    isLoading: isLoadingDelete,
    isError: isErrorDelete,
    mutate: deleteMutate,
    error: errorDelete,
    data: dataDelete,
    isSuccess: deleteSuccess,
  } = useMutation(({ templateId }) => deleteEmailTemplate({ templateId }));

  const {
    isLoading: isLoadingDuplicate,
    isError: isErrorDuplicate,
    isSuccess: isSuccessDuplicate,
    mutate: duplicateMutate,
    error: errorDuplicate,
    data: dataDuplicate,
  } = useMutation(({ templateId }) => duplicateEmailTemplate({ templateId }));

  const handleDeleteEmail = async ({ id }) => {
    await deleteMutate({ templateId: id });
  };

  const { data: eventData, refetch: eventRefetch } = useQuery({
    retry: 0,
    queryKey: ["events"],
    queryFn: () =>
      getEvents({
        page: pagination?.pageIndex,
        limit: LIMIT,
        search: searchEvent,
      }),
  });

  useEffect(() => {
    if (isErrorDuplicate) {
      const erorrData = errorDuplicate?.response?.data?.message;
      enqueueSnackbar(erorrData, { variant: "error" });
    }

    if (isErrorDelete) {
      const erorrData = errorDelete?.response?.data?.message;
      enqueueSnackbar(erorrData, { variant: "error" });
    }

    if (deleteSuccess) {
      const erorrData = dataDelete?.data?.message;
      enqueueSnackbar(erorrData, { variant: "error" });
    }

    if (isSuccessDuplicate) {
      const successMessage = dataDuplicate?.data?.message;
      enqueueSnackbar(successMessage, { variant: "success" });
    }
  }, [isErrorDuplicate, isLoadingDuplicate, isErrorDelete, isLoadingDelete, dataDelete?.data?.message, dataDuplicate?.data?.message, deleteSuccess,errorDelete?.response?.data?.message, errorDuplicate?.response?.data?.message, isSuccessDuplicate ]);

  useEffect(() => {
    eventRefetch();
  }, [debouncedSearchEvent, eventRefetch]);

  useEffect(() => {
    if (eventData) {
      setEvents(() => {
        return eventData?.data.map((event) => {
          return {
            label: event?.title,
            id: event.id,
          };
        });
      });
    }
  }, [eventData]);

  useEffect(() => {
    if (!isLoadingDelete && !isErrorDelete) refetch();
    if (!isLoadingDuplicate && isSuccessDuplicate && !isErrorDuplicate)
      refetch();
  }, [
    isLoadingDelete,
    isLoadingDuplicate,
    pagination.pageIndex,
    debouncedFilters,
    isErrorDelete, 
    isErrorDuplicate,
    isSuccessDuplicate,
    refetch
  ]);

  const handleDuplicateEmail = async ({ id }) =>
    await duplicateMutate({ templateId: id });
  const handleEditEmail = ({ id }) => navigator(`/createEmail?id=${id}`);
  const handleAddEmail = () => navigator("/createEmail");

  const handleChangeFilter = (field, event) => {
    const value = event.target.value;

    if (field === "event") {
      setSearchEvent(value);
      const eventId = events?.find(({ label }) => label === value);

      setFilters((prevState) => {
        return {
          ...prevState,
          eventId: eventId?.id,
        };
      });
    }

    setFilters((prevState) => {
      return {
        ...prevState,
        [field]: value === "placeholder" ? "" : value,
      };
    });
  };

  return (
    <Page
      title="Emails"
      action={<Button onClick={handleAddEmail}>Add Email</Button>}
    >
      <TableFilter
        autocomplitePlaceholder="Event title"
        selectFieldName="type"
        handleFilters={handleChangeFilter}
        selectOptions={filterUserStatus}
        textFieldName="search"
        autocompliteData={events}
        autocompliteTextName="event"
      />
      <Table
        error={error}
        pagination={pagination}
        setPagination={setPagination}
        page={pagination?.pageIndex}
        meta={data?.meta}
        headers={tableHeads}
      >
        {data?.data.map((email) => {
          return (
            <TableRow
              key={email.id}
              sx={{
                background: "#FAFAFA 0% 0% no-repeat padding-box",
                borderBottom: "7px solid white",
              }}
            >
              <CustomTabelCell>{email?.name}</CustomTabelCell>
              <CustomTabelCell>{email?.subject}</CustomTabelCell>
              <CustomTabelCell>{email?.senderName}</CustomTabelCell>
              <CustomTabelCell>{email?.type}</CustomTabelCell>
              <CustomTabelCell>
                {email?.status === "ACTIVE" ? (
                  <Box>
                    <img src={checkCircleIcon} alt="circle" />
                  </Box>
                ) : (
                  <Box>
                    <img src={errorCircleIcon} alt="circle" />
                  </Box>
                )}
              </CustomTabelCell>
              <CustomTabelCell>
                <ActionList
                  actions={[
                    {
                      label: "Edit Email",
                      icon: EditEmail,
                      action: () => handleEditEmail({ id: email.id }),
                    },
                    {
                      label: "Duplicate Email",
                      icon: DuplicateEmailIcon,
                      action: () => handleDuplicateEmail({ id: email.id }),
                    },
                    {
                      label: "Delete Email",
                      icon: DeleteEmailIcon,
                      action: () => handleDeleteEmail({ id: email.id }),
                    },
                  ]}
                />
              </CustomTabelCell>
            </TableRow>
          );
        })}
      </Table>
    </Page>
  );
};

export default EmailsPage;
