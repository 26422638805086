import { Box, TableRow, Typography } from "@mui/material"
import React, { useState } from "react"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import { getBlogs } from "../../api/blogs"
import deleteBlogIcon from "../../assets/icons/deleteEventIcon.png"
import editBlogIcon from "../../assets/icons/editLiveStreamIcon.png"
import ellipsisIcon from "../../assets/icons/ellipsis.png"
import ActionList from "../../components/actionsList/ActionList"
import Button from "../../components/common/button/Button"
import CustomTabelCell from "../../components/common/CustomTebleCell/CustomTableCell"
import Page from "../../components/common/page/Page"
import DeactivateBlogModal from "../../components/modals/deactivateBlogModal/DeactivateBlogModal"
import Table from "../../components/table/Table"
import TableFilter from "../../components/tableFilter/TableFIlter"
import { LIMIT } from "../../constants"

const tableHeads = [
  { label: "Cover" },
  { label: "Title" },
  { label: "Description" },
  { label: "Tags" },
  { label: ellipsisIcon, isIcon: true },
]


const Blogs = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: LIMIT,
  })
  const [isShowDeactivateModal, setIsShowDeactivateModal] = useState(false)
  const navigator = useNavigate()
  const [filters, setFilters] = useState({})
  const [blogId, setBlogId] = useState("")

  const { data, refetch, error } = useQuery({
    retry: 0,
    queryKey: ["Blogs"],
    queryFn: () => getBlogs({ page: pagination.pageIndex, limit: LIMIT }),
  })

  const handleCreateNewBlog = () => navigator("/createBlog")

  const handleEditBlog = ({ blogId }) => {
    navigator(`/createBlog/?blogId=${blogId}`)
  }
  const handleCloseDeactivateModal = () => setIsShowDeactivateModal(false)
  const handleOpenDeactivateModal = ({ blogId }) => {
    setBlogId(blogId)
    setIsShowDeactivateModal(true)
  }
console.log(filters)
  const handleChangeFilter = (field, event) => {
    const value = event.target.value

    setFilters(prevState => {
      return {
        ...prevState,
        [field]: value === "placeholder" ? "" : value,
      }
    })
  }

  return (
    <Page
      title="Blogs"
      action={<Button onClick={handleCreateNewBlog}>Create New Blog</Button>}
    >
      <Box>
        <TableFilter
          // autocompliteData={events}
          hideFilter
          autocompliteTextName="event"
          autocomplitePlaceholder="Livestream Title"
          handleFilters={handleChangeFilter}
          textFieldPlaceholder="Search for Blog"
          // selectOptions={filterTransactionStatus}
          // purchaseTypeOptions={filterOrderType}
          textFieldName="search"
          selectFieldName="status"
        />
        <Box>
          <Table
            pagination={pagination}
            setPagination={setPagination}
            page={pagination?.pageIndex}
            meta={data?.meta}
            error={error}
            headers={tableHeads}
          >
            {data?.data &&
              data?.data.map(blog => {
                return (
                  <TableRow
                    key={blog.id}
                    style={{ fontFamily: "Baguede" }}
                    sx={{
                      background: "#FAFAFA 0% 0% no-repeat padding-box",
                      borderBottom: "7px solid white",
                    }}
                  >
                    <CustomTabelCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={blog.cover}
                          alt=""
                        />
                      </Box>
                    </CustomTabelCell>
                    <CustomTabelCell
                      style={{
                        width: "200px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography
                        sx={{
                          marginLeft: "5px",
                          fontSize: "1.6rem",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                        }}
                      >
                        {blog.title}
                      </Typography>
                    </CustomTabelCell>
                    <CustomTabelCell wraptext={true}>
                      {blog.description}
                    </CustomTabelCell>
                    <CustomTabelCell
                      style={{
                        width: "200px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1.6rem",
                          display: "inline-block",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                        }}
                      >
                        {blog?.tags.map((tag, i) => (
                          <React.Fragment key={i}>
                            {tag}
                            {i !== blog.tags.length - 1 && ", "}
                          </React.Fragment>
                        ))}
                      </Typography>
                    </CustomTabelCell>

                    <CustomTabelCell>
                      <ActionList
                        actions={[
                          {
                            label: "Edit Blog",
                            icon: editBlogIcon,
                            action: () => handleEditBlog({ blogId: blog.id }),
                          },
                          {
                            label: "Delete Blog",
                            icon: deleteBlogIcon,
                            action: () =>
                              handleOpenDeactivateModal({ blogId: blog.id }),
                          },
                        ]}
                      />
                    </CustomTabelCell>
                  </TableRow>
                )
              })}
          </Table>
        </Box>
      </Box>
      {isShowDeactivateModal && (
        <DeactivateBlogModal
          blogId={blogId}
          refetch={refetch}
          handleClose={handleCloseDeactivateModal}
        />
      )}
    </Page>
  )
}

export default Blogs
