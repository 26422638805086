import { Box, TableRow } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

import { LIMIT } from "../../constants";
import CustomTabelCell from "../../components/common/CustomTebleCell/CustomTableCell";
import TableFilter from "../../components/tableFilter/TableFIlter";
import useDebounce from "../../hooks/useDebounce";
import Table from "../../components/table/Table";
import ActionList from "../../components/actionsList/ActionList";
import Page from "../../components/common/page/Page";
import Button from "../../components/common/button/Button";
import editArtistIcon from "../../assets/icons/editLiveStreamIcon.png";
import deleteArtistIcon from "../../assets/icons/deleteEventIcon.png";
import DeactivateContentModal from "../../components/modals/deactivateContentModal/DeactivateContentModal";
import { getContents, updateContentPosition } from "../../api/contents/contents";
import { convertDate } from "../../utils/convertDate";
import { enqueueSnackbar } from "notistack";

const FeaturedContent = () => {
  const navigate = useNavigate();
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [contentList, setContentList] = useState([]);
  const [isShowSearchField, setIsShowSearchField] = useState(false);
  const [isShowDeactivateModal, setIsShowDeactivateModal] = useState(false);
  const [contentId, setContentId] = useState("");
  const [filters, setFilters] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: LIMIT,
  });
  const debouncedFilters = useDebounce(filters, 300);

  const { data, refetch, error } = useQuery({
    retry: 0,
    queryKey: ["Contents", pagination.pageIndex, debouncedFilters],
    queryFn: () =>
      getContents({
        page: pagination.pageIndex,
        limit: LIMIT,
        filters: debouncedFilters,
      }),
    onSuccess: (response) => {
      setContentList(response?.data || []);
    },
  });

  const { mutate: updateOrder } = useMutation(updateContentPosition);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const updatedList = Array.from(contentList);
    const [movedItem] = updatedList.splice(source.index, 1);
    updatedList.splice(destination.index, 0, movedItem);

    setContentList(updatedList);

    // Prepare the payload for the updateContentsOrder API call using displayPosition
    const payload = {
      position1: contentList[source.index].displayPosition,
      position2: contentList[destination.index].displayPosition,
    };

    // Send the updated order to the backend
    updateOrder({ positions: payload }, {
      onSuccess: () => {
        enqueueSnackbar("Content positions swappedd successfully", { variant: "success" });
        refetch(); // Optionally refetch data after successful update
      }
    });
  };

  const handleCreateNewContent = () => navigate("/createContent");
  const handleEditContent = (contentId) => {
    navigate(`/createContent?id=${contentId}`);
  };

  const handleShowFilter = () => setIsShowFilter((prevState) => !prevState);
  const handleCloseDeactivateModal = () => setIsShowDeactivateModal(false);
  const handleOpenDeactivateModal = (contentId) => {
    setContentId(contentId);
    setIsShowDeactivateModal(true);
  };
  const handleShowSearchField = () =>
    setIsShowSearchField((prevState) => !prevState);

  useEffect(() => {
    refetch();
  }, [pagination.pageIndex, debouncedFilters, refetch]);

  const handleChangeFilter = (field, event) => {
    const value = event.target.value;

    setFilters((prevState) => ({
      ...prevState,
      [field]: value === "placeholder" ? "" : value,
    }));
  };

  return (
    <Page
      title="Featured Contents"
      action={<Button onClick={handleCreateNewContent}>Create Content</Button>}
    >
      <Box>
        <TableFilter
          autocompliteData={contentList}
          hideFilter
          autocompliteTextName="event"
          autocomplitePlaceholder="Livestream Title"
          textFieldName="search"
          selectFieldName="status"
          handleFilters={handleChangeFilter}
          textFieldPlaceholder="Search for Content"
          handleShowFilter={handleShowFilter}
          isShowFilter={isShowFilter}
          handleShowSearchField={handleShowSearchField}
          isShowSearchField={isShowSearchField}
        />
        <Table
          pagination={pagination}
          setPagination={setPagination}
          page={pagination?.pageIndex}
          meta={data?.meta}
          error={error}
          tableType="Users"
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="manage-content">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {contentList.map((content, index) => (
                    <Draggable
                      key={content.displayPosition}
                      draggableId={`${content.displayPosition}`}
                      index={index}
                    >
                      {(provided) => (
                        <TableRow
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          key={content.displayPosition}
                          sx={{
                            background: "#FAFAFA 0% 0% no-repeat padding-box",
                            borderBottom: "7px solid white",
                          }}
                        >
                          <CustomTabelCell>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <video
                                style={{
                                  width: "100px",
                                  height: "50px",
                                  borderRadius: "12px",
                                }}
                                loop
                                muted
                                autoPlay
                                playsInline
                                width="640"
                                preload="auto"
                              >
                                <source
                                  src={content?.videoUrl}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  flexDirection: "column",
                                  gap: "10px",
                                }}
                              >
                                <span>{content?.title}</span>
                                <span>
                                  {convertDate(content?.updatedAt, "-")}
                                </span>
                              </Box>
                            </Box>
                          </CustomTabelCell>
                          <CustomTabelCell wraptext={true}>
                            {content.description}
                          </CustomTabelCell>
                          <CustomTabelCell>
                            <ActionList
                              actions={[
                                {
                                  label: "Edit Content",
                                  icon: editArtistIcon,
                                  action: () => handleEditContent(content.id),
                                },
                                {
                                  label: "Deactivate Content",
                                  icon: deleteArtistIcon,
                                  action: () =>
                                    handleOpenDeactivateModal(content.id),
                                },
                              ]}
                            />
                          </CustomTabelCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </Box>

      {isShowDeactivateModal && (
        <DeactivateContentModal
          contentId={contentId}
          refetch={refetch}
          handleClose={handleCloseDeactivateModal}
        />
      )}
    </Page>
  );
};

export default FeaturedContent;
