import { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import { getUserGroups, } from '../../api/userGropup';
import { createAdmin, getAdmin, updateAdmin } from '../../api/admins';
import Page from '../../components/common/page/Page';
import AddEditAdminForm from '../../forms/adminsForms/AddEditAdminForm';

const CreateNewAdminPage = () => {
  const navigator = useNavigate();
  const [isNewUser, setIsNewUser] = useState(false);

  const {
    data,
  } = useQuery({
    queryKey: ['userGroups'],
    queryFn: () => getUserGroups({ page: 1, limit: 10 }),
  });


  const {
    isLoading: createAdminLoading,
    mutate: createAdminMutate,
  } = useMutation(({ value, notify }) => createAdmin({ value, notify }));

  const {
    mutate: updateAdminMutate,
  } = useMutation(updateAdmin);

  const {
    isLoading: getAdminLoading,
    data: getAdminData,
    mutate: getAdminMutate
  } = useMutation(getAdmin)


  const getUserGroupOptions = () => {
    if (data?.data) {
      return data?.data.map(( { name, id } ) => {
        return {
          label: name,
          value: id
        }
      })
    }

    return [];
  }

  const handleSubmit = (value, notify) => {
    if (isNewUser) {
      createAdminMutate({ value, notify }, {
        onSuccess: () => {
          if (!notify) navigator('/admins');
          enqueueSnackbar('User created', { variant: 'success' });
          navigator('/admins');
        },
        onError: (error) => {
          const message = error?.response?.data?.message;
          enqueueSnackbar(message ? message : 'Error', { variant: 'error' });
          // if (!notify) navigator('/admins');
        }
      })
      return;
    }
    updateAdminMutate({ value, notify }, {
      onSuccess: () => {
        if (!notify) navigator('/admins');
        enqueueSnackbar('User updated', { variant: 'success' });
        navigator('/admins');
      },
      onError: (error) => {
        const message = error?.response?.data?.message;
        enqueueSnackbar(message ? message : 'Error', { variant: 'error' });
        // if (!notify) navigator('/admins');
      }
    });
  }

  useEffect(() => {
    const params = new URL(window.location).searchParams;
    const id = params.get('userId');

    if (id) {
      getAdminMutate({ id: id });
      return setIsNewUser(() => false);
    }

    setIsNewUser(() => true);
  }, [getAdminMutate]);

  return (
    <Page title='Add/Edit Admin'>
      <AddEditAdminForm
        userGroups={getUserGroupOptions()}
        submitFunction={handleSubmit}
        userData={getAdminData}
        isLoading={createAdminLoading || getAdminLoading}
        navigator={navigator}
        isNewUser={isNewUser}
      />
    </Page>
  )
}

export default CreateNewAdminPage;