import {
  convertToRaw,
  EditorState,
  convertFromRaw,
} from "draft-js";
import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FormWrapper from "../../components/common/formWrapper/FormWrapper";
import { Form, Field, useFormikContext } from "formik";
import { ButtonGroup, FormLabel, Grid } from "@mui/material";
import Button from "../../components/common/button/Button";
import { useNavigate } from "react-router-dom";
import Input from "../../components/common/input/Input";
import UploadEventPrint from "../../components/common/uploadEventPrint/UploadEventPrint";
import { useEffect } from "react";
import Tags from "../../components/tags/tags";
import { uploadImage } from "../../api/files";
import { enqueueSnackbar } from "notistack";
import Select from "../../components/common/select/Select";

export const CreateBlogForm = ({ isLoading, isError, touched }) => {
  const {
    setFieldValue,
    submitForm,
    validateForm,
    errors,
    values: { tags, content, cover, category  },
  } = useFormikContext();

  const initialEditorState = content
    ? EditorState.createWithContent(convertFromRaw(JSON.parse(content)))
    : EditorState.createEmpty();

  const [editorState, setEditorState] = useState(initialEditorState);
  const [blogContent, setBlogContent] = useState(content || "");
  const navigate = useNavigate();
  const [tmpPreview, setTmpPreview] = useState({});

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const rawContent = convertToRaw(editorState.getCurrentContent());
    setBlogContent(JSON.stringify(rawContent));
  };

  const handleImageUpload = (file) => {
    return new Promise((resolve, reject) => {
      uploadImage(file)
        .then((imageUrl) => {
          resolve({ data: { link: imageUrl?.data?.Location } });
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          reject(error);
        });
    });
  };

  const handleSubmit = async () => {
    const validationErrors = await validateForm();
    if (Object.keys(validationErrors).length > 0) {
      const firstErrorKey = Object.keys(validationErrors)[0];
      enqueueSnackbar(`${firstErrorKey}: ${validationErrors[firstErrorKey]}`, {
        variant: "error",
      });
    } else {
      await submitForm();
    }
  };

  const handleTagsChange = (tags) => {
    setFieldValue("tags", tags);
  };

  const customStyleMap = {
    IMAGE: {
      display: "block",
      height: "auto",
      margin: "0 auto",
      maxHeight: "200px",
      maxWidth: "200px",
    },
  };

  useEffect(() => {
    if (blogContent) {
      setFieldValue("content", blogContent);
    }
  }, [blogContent, setFieldValue]);

  return (
    <FormWrapper>
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={24} md={6} lg={6}>
            <Field label="Blog Title" name="title" component={Input} />
          </Grid>
          <Grid sx={{ marginTop: "-10px" }} item xs={24} md={6} l6={6}>
            <Field
              component={Select}
              label="Blog Category*"
              name="category"
              defaultValue={{value: category}}
              options={[
                { label: "Technology", value: "technology" },
                { label: "Entertainment", value: "entertainment" },
                { label: "War", value: "war" },
              ]}
              error={touched?.category && !!errors?.category}
              helperText={errors?.category}
            />
          </Grid>
          <Grid item xs={24} md={24} lg={24}>
            <Tags initialTags={tags || []} onChange={handleTagsChange} />
          </Grid>
          <Grid item xs={24} md={6} lg={6}>
            <Field
              label="Blog Description"
              name="description"
              component={Input}
            />
          </Grid>
          <Grid item xs={24} md={6} lg={6}>
            <Field label="Blog Short Clip" name="videoUrl" component={Input} />
          </Grid>
          <Grid item xs={24} md={6} lg={6}>
            <UploadEventPrint
              tmpPreview={tmpPreview}
              setTmpPreview={setTmpPreview}
              item={cover}
              blockName="blogCoverImageUrl"
              label="Upload Png File for Blog Cover"
              subText="Image will be resized to width: 499px, height: 722px"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid sx={{ marginTop: "50px" }} item xs={12} md={12} lg={12}>
            <FormLabel sx={{ fontSize: "2rem" }}>Blog Content</FormLabel>
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
              toolbar={{
                options: [
                  "inline",
                  "textAlign",
                  "blockType",
                  "list",
                  "link",
                  "image",
                ],
                image: {
                  uploadCallback: handleImageUpload,
                  alt: { present: true, mandatory: false },
                  defaultSize: {
                    height: "auto",
                    width: "100%",
                  },
                },
              }}
              customStyleMap={customStyleMap}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} md={6} lg={5}>
            <Button
              type="button"
              onClick={() => {
                navigate("/blogs");
              }}
            >
              Cancel
            </Button>
          </Grid>

          <Grid item xs={12} sm={8} md={6} lg={5}>
            <ButtonGroup
              sx={{
                gap: "3rem",
                marginTop: "auto",
                width: "100%",
                alignItems: "center",
                justifyContent: "flex-end",
                flexWrap: "wrap",
              }}
            >
              <Button
                type="submit"
                isLoading={isLoading}
                isModal
                onClick={handleSubmit}
              >
                Publish & Exit
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Form>
    </FormWrapper>
  );
};
