import { Field, Form, Formik } from "formik";
import { useMutation } from "react-query";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";

import { forgotPasswordValidate } from "../../validates/forgotPasswordValidate";
import { forgotPassword } from "../../api/auth";
import Input from "../../components/common/input/Input";
import Button from "../../components/common/button/Button";

const ForgotPasswordForm = () => {
  const { isLoading, mutate, isSuccess } =
    useMutation(forgotPassword);

  useEffect(() => {
    if (!isLoading && isSuccess)
      enqueueSnackbar("Letter sent", { variant: "success" });
  }, [isLoading, isSuccess]);

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      onSubmit={({ email }) => {
        const redirectUrl = `${process.env.REDIRECT_URL}/resetPassword`;

        mutate({
          email,
          redirectUrl,
        });
      }}
      validationSchema={forgotPasswordValidate}
    >
      {({ errors, touched }) => {
        return (
          <Form>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  gap: "5rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Typography
                    sx={{ paddingBottom: "1.5rem" }}
                    variant="h2"
                    component="h2"
                  >
                    Forgot Password
                  </Typography>
                  <Typography variant="h4" component="h4">
                    Input the email address tied to your admin account
                  </Typography>
                </Box>
                <Box
                  sx={{
                    gap: "2rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Field
                    component={Input}
                    label="Enter Email Address"
                    name="email"
                    placeholder="Email Address"
                    error={errors?.email && touched?.email}
                    helperText={errors?.email}
                  />
                </Box>
                <Grid container direction="column" spacing={5}>
                  <Grid item>
                    <Button type="submit" isLoading={isLoading}>
                      Reset Password
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ForgotPasswordForm;
