import { Formik } from "formik"
import Page from "../../components/common/page/Page"
import { CreateBlogForm } from "../../forms/createBlog/CreateBlog"
import { createBlogValidate } from "../../validates/createBlog"
import { createBlog, getBlogById } from "../../api/blogs"
import { useMutation, useQuery } from "react-query"
import { useCallback, useEffect, useState } from "react"
import { enqueueSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"

const CreateBlog = () => {
  const [blogId, setBlogId] = useState(null)
  const navigate = useNavigate()
  const {
    isLoading: createArtistLoading,
    mutate,
    error,
  } = useMutation(values => {
    return createBlog(values)
  })

  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors }) => {
      // Convert phoneNumber to string and rename image to profileImage
      const payload = {
        ...values,
      }
      return mutate(
        { value: payload },
        {
          // Pass values as an object with a `value` property
          onSuccess: () => {
            enqueueSnackbar("Blog is successfully created", {
              variant: "success",
            })
            navigate("/blogs")
          },
          onError: error => {
            const errorData = error?.response?.data?.message
            if (Array.isArray(errorData)) {
              enqueueSnackbar(`${errorData[0]}`, { variant: "error" })
            } else {
              enqueueSnackbar(`${errorData}`, { variant: "error" })
            }
            if (errorData) {
              const formErrors = {}
              errorData.forEach(err => {
                const field = "error"
                formErrors[field] = err
              })
              setErrors(formErrors)
            }
            setSubmitting(false)
          },
        }
      )
    },
    [mutate, navigate]
  )
  const { data: blogData, isLoading } = useQuery(
    ["blog-Id", blogId],
    () => getBlogById({ blogId }),
    {
      enabled: !!blogId,
    }
  )

  useEffect(() => {
    const params = new URL(window.location).searchParams
    const id = params.get("blogId")

    if (id) {
      setBlogId(id)
    }
  }, [])
  return (
    <Page title="Add/Edit Blog">
      {!isLoading && (
        <Formik
          initialValues={{
            description: blogData?.data?.description || "",
            title: blogData?.data?.title || "",
            category: blogData?.data?.category || "",
            tags: blogData?.data?.tags || "",
            cover: blogData?.data?.cover || "",
            content: blogData?.data?.content || "",
            videoUrl: blogData?.data?.videoUrl || "",
          }}
          onSubmit={handleSubmit}
          validationSchema={createBlogValidate}
          enableReinitialize={true}
        >
          {({ errors, touched, values }) => {
            return (
              <CreateBlogForm
                touched={touched}
                isLoading={createArtistLoading}
                isError={error}
              />
            )
          }}
        </Formik>
      )}
    </Page>
  )
}

export default CreateBlog
