import { fetchClient } from "../axiosinterceptor";


export const createBlog = async (values) => {
    const { data } = await fetchClient().post("admins-blog", {
      ...values.value,
    });
  
    return data;
  };
  
  export const deactivateBlog = async ({ blogId }) => {
    const { data } = await fetchClient().delete(`admins-blog/${blogId}`);
  
    return data;
  };
  
  export const getBlogById = async ({ blogId }) => {
    const { data } = await fetchClient().get(`blogs/${blogId}`);
  
    return data;
  };

  export const getBlogs = async ({ page, limit   }) => {
    const { data } = await fetchClient().get(`blogs?limit=${limit}&page=${page}`
    );
    return data;
  };