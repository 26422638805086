import { Formik } from "formik"
import { enqueueSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useNavigate } from "react-router-dom"

import dayjs from "dayjs"
import { createArtist } from "../../api/artist/"
import { createEvent, editEvent, getEventById } from "../../api/events"
import { getSinglePass } from "../../api/passes"
import Page from "../../components/common/page/Page"
import EventForm from "../../forms/eventForm/EventForm"
import { setInitialStateForm } from "../../forms/eventForm/setInitialStateForm"
import LiveStreamLayout from "../../layouts/liveStreamsLayout/LiveStreamLayout"
import { event } from "../../validates/eventValidate"

const EVENT_INFORMATION_TAB_INDEX = 0
const EVENT_CONTENT_TAB_INDEX = 1
const STREAM_PASSES_TAB_INDEX = 2
const LIVESTREAM_CONTENT_TAB_INDEX = 3

const LiveStreamInfoPage = () => {
  const params = new URL(window.location).searchParams
  const navigate = useNavigate()
  const eventId = params.get("eventId")
  const tab = params.get("tab")
  const [activeHeadNavMenuId, setActiveHeadNavMenuId] = useState(0)

  const handleChangeActiveHeadMenu = id => setActiveHeadNavMenuId(() => id)
  const navigator = useNavigate()

  const { isLoading, isError, isSuccess, mutate, error } = useMutation(
    values => {
      return createEvent(values)
    }
  )

  const {
    isLoading: isLoadingEditEvent,
    isError: isErrorEditEvent,
    isSuccess: isSuccessEdit,
    mutate: mutateEdit,
    error: errorEditEvent,
  } = useMutation(values => {
    return editEvent(values)
  })

 useMutation(value =>
    createArtist({ value })
  )

  const { data: singleEventData, isLoading: singleEventIsLoading } = useQuery(
    ["getSingleEvent", eventId],
    () => getEventById({ eventId }),
    {
      enabled: !!eventId,
    }
  )

  const { data: dataPasses } = useQuery(
    ["getSinglePass", eventId],
    () => getSinglePass({ eventId }),
    {
      enabled: !!eventId,
    }
  )

  const changeTab = tab => {
    const params = new URL(window.location).searchParams
    const currentTab = params.get("tab")

    if (!currentTab) {
      navigator(
        `${
          window.location.search ? window.location.search : "?"
        }&tab=eventInformation`
      )
    }

    return window.location.search.replace(/(tab)=[^&]*/g, `${tab}`)
  }

  const handleSubmit = async values => {
    const params = new URL(window.location).searchParams
    const eventId = params.get("eventId")
    const parseStartTime = `${dayjs(values?.startTime).get("hours")}:${dayjs(
      values?.startTime
    ).get("minutes")}:${dayjs(values?.startTime).get("seconds")}`
    const parseEndTime = `${dayjs(values?.endTime).get("hours")}:${dayjs(
      values?.endTime
    ).get("minutes")}:${dayjs(values?.startTime).get("seconds")}`

    const startDate = `${values?.startMonth}/${values?.startDay}/${values?.startYear} ${parseStartTime}`
    const endDate = `${values?.endMonth}/${values?.endDay}/${values?.endYear} ${parseEndTime}`
    const startTime = dayjs(startDate).valueOf()
    const endTime = dayjs(endDate).valueOf()

    const valuesToEdit = {
      ...values,
      eventId,
      startAt: startTime,
      endAt: endTime,
    }
    if (eventId) {
      return mutateEdit(valuesToEdit, {
        onSuccess: () => {
          navigate("/liveStream")
        },
      })
    }

    const passes = values?.passes.map(pass => {
      return {
        ...pass,
        maxPerUser: Number(pass?.maxPerUser),
        enableNGN: Boolean(pass?.enableNGN),
        enableUSD: Boolean(pass?.enableUSD),
        enableGBP: Boolean(pass?.enableGBP),
        enableMTN: Boolean(pass?.enableMTN),
        priceGBP: Number(pass?.priceGBP),
        priceNGN: Number(pass?.priceNGN),
        priceUSD: Number(pass?.priceUSD),
        priceMTN: Number(pass?.priceMTN),
      }
    })

    mutate(
      {
        ...values,
        passes: passes,
        startAt: startTime,
        endAt: endTime,
      },
      {
        onSuccess: () => {
          navigate("/liveStream")
        },
      }
    )
  }

  const navbarLinks = [
    { label: "Event Information", url: changeTab("tab=eventInformation") },
    { label: "Event Content", url: changeTab("tab=eventContent") },
    { label: "Stream Passes", url: changeTab("tab=streamPasses") },
    { label: "Livestream Content", url: changeTab("tab=livestreamContent") },
  ]

  useEffect(() => {
    if (!isLoading && isError) {
      const errorResponseData = error?.response?.data?.message
      enqueueSnackbar(errorResponseData ? errorResponseData : "Error", {
        variant: "error",
      })
    }

    if (!isLoading && !isError && isSuccess)
      enqueueSnackbar("Event created", { variant: "success" })

    if (!isLoadingEditEvent && isErrorEditEvent) {
      const errorResponseData = errorEditEvent?.response?.data

      enqueueSnackbar(errorResponseData ? errorResponseData : "Error", {
        variant: "error",
      })
    }

    if (!isLoadingEditEvent && !isErrorEditEvent && isSuccessEdit)
      enqueueSnackbar("Event updated", { variant: "success" })
    // eslint-disable-next-line
  }, [isLoading, isLoadingEditEvent, isErrorEditEvent])

  useEffect(() => {
    if (!tab) return setActiveHeadNavMenuId(EVENT_INFORMATION_TAB_INDEX)
    if (tab === "eventInformation")
      return setActiveHeadNavMenuId(EVENT_INFORMATION_TAB_INDEX)
    if (tab === "eventContent")
      return setActiveHeadNavMenuId(EVENT_CONTENT_TAB_INDEX)
    if (tab === "streamPasses")
      return setActiveHeadNavMenuId(STREAM_PASSES_TAB_INDEX)
    if (tab === "sprayMoney")
      return setActiveHeadNavMenuId(LIVESTREAM_CONTENT_TAB_INDEX)
    // eslint-disable-next-line
  }, [tab])

  return (
    <Page title="Add/Edit Livestream">
      {!singleEventIsLoading && (
        <Formik
          initialValues={setInitialStateForm(singleEventData, dataPasses)}
          validationSchema={event}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ errors, values, touched, dirty }) => {
            return (
              <LiveStreamLayout
                navbarLinks={navbarLinks}
                handleChangeActiveHeadMenu={handleChangeActiveHeadMenu}
                activeHeadNavMenuId={activeHeadNavMenuId}
                isDisableChangeTab={!singleEventData}
              >
                <EventForm
                  isLoading={isLoading || isLoadingEditEvent}
                  errors={errors}
                  touched={touched}
                  dirty={dirty}
                  handleChangeActiveHeadMenu={handleChangeActiveHeadMenu}
                  activeHeadNavMenuId={activeHeadNavMenuId}
                />
              </LiveStreamLayout>
            )
          }}
        </Formik>
      )}
    </Page>
  )
}

export default LiveStreamInfoPage
