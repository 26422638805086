import { fetchClient } from "../axiosinterceptor";
import { convertDate } from "../../utils/convertDate";
import CountryList from 'country-list-with-dial-code-and-flag';

export const getUser = async (id) => {
  const { data } = await fetchClient().get(`/users/${id}`);

  return data;
};

export const updateWallet = async ({
  id,
  values: { enterAmount, enterPassword, chooseReason },
}) => {
  const { data } = await fetchClient().put(`/users/${id}/wallet/update`, {
    amount: enterAmount,
    reason: chooseReason,
    adminPassword: enterPassword,
  });

  return data;
};


export const updateUser = async ({
  userId,
  values: {
    gender,
    year,
    month,
    birthday,
    userName,
    emailAddress,
    countryCode,
    phoneNumber,
  },
}) => {
  const dateOfBirth = convertDate(new Date(year, month - 1, birthday), "/");

  const { data } = await fetchClient().put(`users/update/${userId}`, {
    gender: gender,
    username: userName,
    email: emailAddress,
    countryCode: countryCode,
    birthday: dateOfBirth,
    phoneNumber: `${countryCode}${phoneNumber}`,
  });

  return data;
};

export const createNewUser = async ({
  userId,
  values: {
    gender,
    year,
    month,
    birthday,
    userName,
    emailAddress,
    countryCode,
    phoneNumber,
    password,
    image,
    imageType,
    firstName,
    lastName,
  },
}) => {
  const dateOfBirth = convertDate(new Date(year, month - 1, birthday), "/");

  let data = {
    gender,
    password,
    username: userName,
    email: emailAddress,
    countryCode: countryCode,
    phoneNumber: `${countryCode}${phoneNumber}`,
    firstName,
    lastName,
    countryShortName: CountryList.findOneByDialCode("+234").data.code
  };

  if (year && month && birthday) {
    data.birthday = dateOfBirth;
  }

  if (imageType || image) {
    data.imageType = imageType;
    data.image = image;
  }

  return await fetchClient().post("/admin-user", {
    ...data,
  });
};

export const getUsers = async ({ page, limit, search, status, eventId }) => {
  const { data } = await fetchClient().get(
    `users/limit/${limit}/page/${page}`,
    {
      params: {
        s: search,
        status,
        eventId,
      },
    }
  );

  return data;
};

export const deleteUser = async ({ userId, reason, adminPassword }) => {
  const { data } = await fetchClient().delete(`/users/${userId}`, {
    data: {
      reason,
      adminPassword,
    },
  });

  return data;
};

export const blockUser = async ({ userId, reason, adminPassword }) => {
  const { data } = await fetchClient().put(`/users/block/${userId}`, {
    reason,
    adminPassword,
  });

  return data;
};
