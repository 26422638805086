import Popover from '@mui/material/Popover';
import ellipses from '../../assets/icons/ellipsis.png';
import ActionListItem from './ActionsListItem/ActionListItem';
import './StylesActionList.scss';
import {useState} from "react";

const ActionList = ({ actions, user }) => {
  // const { ref, isComponentVisible, setIsComponentVisible } = useOutsideClick(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

  return (
    <div className='ActionList'>
      <button
        className='ActionList__activator'
        onClick={handleClick}
      >
        <img src={ellipses} alt='ellipses'/>
      </button>
          <Popover
          id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            >
              <ul>
                  {
                      actions && actions.map((item, idx) => {
                          if (item?.status === 'SUCCESS' && (item.label === 'Validate Pending' || item?.label === 'Mark as Complete')) {
                              return null;
                          }
                          return (
                              <ActionListItem
                                  user={user}
                                  item={item}
                                  key={`${item.label}_${idx}`}
                                  handleClose={handleClose}
                              />
                          )
                      })
                  }
              </ul>
        </Popover>
    </div>
  )
}

export default ActionList;