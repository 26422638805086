import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { enqueueSnackbar } from 'notistack'

import Modal from '../../common/modal/Modal';
import Button from '../../common/button/Button';
import './StylesDeactivateArtistModal.scss';
import { deactivateArtist } from '../../../api/artist';

const DeactivateArtistModal = ({ handleClose, artistId, refetch }) => {
  const {
    isLoading,
    isError,
    isSuccess,
    mutate
  } = useMutation(() => deactivateArtist({artistId}));


  const handleDeactivate = () => mutate();

  useEffect(() => {
    if (!isError && !isLoading && isSuccess) {
      enqueueSnackbar('Artist deactivated', { variant: 'success' });
      refetch();
      handleClose();
    }

    if(!isLoading && isError) {
      enqueueSnackbar('Issues with artist deactivation', { variant: 'error' });
      handleClose();
    }
  }, [isLoading, isError, handleClose, isSuccess, refetch])

  return (
    <Modal
      title='Deactivate Artist'
      onClose={handleClose}
    >
      <div className='DeactivateArtistModal'>
        <span>
          Please confirm that you would like to mark this blog as deactivated.
        </span>
        <Button
          isModal
          isLoading={isLoading}
          onClick={handleDeactivate}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

export default DeactivateArtistModal;