import { 
  Box, 
  TableBody, 
  TableContainer, 
  Table as MaterialTable, 
  TablePagination, 
  TableFooter, 
  TableHead, 
  TableRow, 
  TableCell,
} from '@mui/material';

import TableEmptyState from '../common/tableEmptyState/TableEmptyState';
import arrow from '../../assets/icons/arrow.svg';
import {useCallback} from "react";

const Table = ({ setPagination, headers, children, meta, page, error, tableType }) => {
  const handleNext = useCallback(() => { 
    if(!meta?.hasNextPage) {
      return
    }
    setPagination((prevState) => ({...prevState, pageIndex: prevState.pageIndex + 1}))
  }, [setPagination, meta])

  const handlePrev = useCallback(() => {
    if(!meta?.hasPrevPage) {
      return
    }
    setPagination((prevState) => ({...prevState, pageIndex: prevState.pageIndex - 1}))
  }, [setPagination, meta])

  if (error && error?.response?.status === 404) {
    return (
      <TableEmptyState
        tableType={tableType}
      />
    ) 
  }

  return (
    <Box>
      <TableContainer>
        <MaterialTable 
          sx={{ minWidth: 900 }}
          aria-label='simple table'
          
        >
          <TableHead
            sx={{
              background: '#F5F4F4 0% 0%',
            }}
          >
            <TableRow>
            {
              headers?.map(({ label, isIcon }) => {
                return (
                  <TableCell
                    
                    key={`${label}`}
                    sx={{
                      fontSize: '1.7rem',
                      fontWeight: 200,
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {isIcon ? <img src={label} alt=''/> : label}
                  </TableCell>
                )
              })
            }
            </TableRow>
          </TableHead>
          <TableBody>
            {children}
          </TableBody>
        </MaterialTable>
        <TableFooter sx={{
          width: '100%',
          display: 'flex',
        }}>
            <TablePagination
              rowsPerPage={10}
              count={-1}
              onPageChange={() => {}}
              page={page}
              component={() => {
                return (
                  <Box sx={{
                    background: '#F5F4F4 0% 0% no-repeat padding-box',
                    width: '100%',
                    display: 'flex',
                    fontSize: '1.8rem',
                  }}>
                    <Box sx={{
                      padding: '1.6rem 3rem',
                      display: 'flex',
                      width: '100%',
                    }}>
                      <Box>
                        {`Page ${page} of ${meta?.totalPages ? meta?.totalPages : page}`}
                      </Box>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 'auto'
                      }}>
                        <Box
                          onClick={handlePrev}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            opacity: meta?.hasPrevPage ? '' : '.1',
                            cursor: meta?.hasPrevPage ? 'pointer' : '',
                          }}
                        >
                          <img src={arrow} alt=''/>
                        </Box>
                        <Box sx={{
                          margin: '0 1rem'
                        }}>{`Page ${page} of ${meta?.totalPages ? meta?.totalPages : page}`}</Box>
                        <Box 
                          onClick={handleNext}
                          sx={{
                            rotate: '180deg',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            opacity: meta?.hasNextPage ? '' : '.1',
                            cursor: meta?.hasNextPage ? 'pointer' : '',
                          }}
                          >
                          <img src={arrow} alt=''/>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )
              }}
            />
          </TableFooter>
      </TableContainer>
    </Box>
  )
} 

export default Table;