import { fetchClient } from '../axiosinterceptor';

export const getAdmins = async ({ limit, page, search, status, role }) => {
  const { data } = await fetchClient().get(`admins/limit/${limit}/page/${page}`, {
    params: {
      status,
      [role ? 'role' : '']: role,
      s: search,
    }
  });

  return data;
};

export const getAdmin = async ({ id }) => {
  const { data } = await fetchClient().get(`admins/${id}`);

  return data
}

export const createAdmin = async ({ value, notify }) => {
  const REACT_APP_HOST = process.env.REACT_APP_BASE_URL;
  
  const {email, firstName, lastName, phoneNumber,password, roleId} = value
  return await fetchClient().post('admins', {
    email,
    status: 'ACTIVE',
    firstName,
    lastName,
    password,
    phoneNumber: String(phoneNumber),
    roleId,
    confirmEmailUrl: `${REACT_APP_HOST}/resetPassword`,
  });
}

export const updateAdmin = async ({value}, notify) => {
  const { email, status, firstName, lastName, phoneNumber, roleId, id } = value;
  return await fetchClient().put(`admins/update/${id}`, {
    email,
    status,
    firstName,
    lastName,
    phoneNumber: String(phoneNumber),
    roleId,
  });
}

export const deactivateAdmin = async ({ id }) => await fetchClient().put(`/admins/update/${id}`, {
  status: 'DEACTIVATED'
});