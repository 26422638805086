import { Grid, ButtonGroup, Box } from "@mui/material";
import { Field, Form, useFormikContext } from "formik";

import Input from "../../components/common/input/Input";
import Button from "../../components/common/button/Button";
import FormWrapper from "../../components/common/formWrapper/FormWrapper";
import UploadEventPrint from "../../components/common/uploadEventPrint/UploadEventPrint";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import useDebounce from "../../hooks/useDebounce";
import { LIMIT } from "../../constants";
import { getEvents } from "../../api/events";
import { useQuery } from "react-query";
import Autocomplete from "../../components/common/autocomplete/Autocomplite";

const ContentForm = ({ isLoading, navigator }) => {
  const [tmpPreview, setTmpPreview] = useState({});
  const [search, setSearch] = useState("");
  const [events, setEvents] = useState([]);
  const {
    values: { imageUrl },
    submitForm,
    setFieldValue,
    validateForm,
  } = useFormikContext();
  const debouncedSearchTerm = useDebounce(search, 300);

  const handleSubmit = async () => {
    const validationErrors = await validateForm();
    if (Object.keys(validationErrors).length > 0) {
      const firstErrorKey = Object.keys(validationErrors)[0];
      enqueueSnackbar(`${firstErrorKey}: ${validationErrors[firstErrorKey]}`, {
        variant: "error",
      });
    } else {
      await submitForm();
    }
  };

  const handleChangeSearch = ({ label }) => {
    setSearch(label);
    console.log("serach", label);
  };

  const {
    isLoading: isLoadingEvent,
    data: eventData,
    refetch,
  } = useQuery({
    retry: 0,
    queryKey: ["events"],
    queryFn: () => getEvents({ page: 1, limit: LIMIT, search }),
  });

  useEffect(() => {
    if (!isLoadingEvent) {
      setEvents(() => {
        return eventData?.data.map(({ title, id }) => {
          return {
            label: title,
            id,
          };
        });
      });
    }
  }, [isLoadingEvent, eventData?.data]);

  useEffect(() => {
    refetch();
    setEvents(() => {
      return eventData?.data.map(({ title, id }) => {
        return {
          label: title,
          id,
        };
      });
    });
  }, [debouncedSearchTerm, eventData?.data, refetch]);
  return (
    <FormWrapper>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Field label="Content Title *" name="title" component={Input} />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Field
              label="Content Short Clip"
              name="videoUrl"
              component={Input}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <UploadEventPrint
              type={"horizontal"}
              tmpPreview={tmpPreview}
              mustBeLandscape
              setTmpPreview={setTmpPreview}
              blockName="contentCoverImageUrl"
              label="Upload Cover Png File for Content"
              subText="Image will be resized to width: 499px, height: 722px"
              subText2="Recommended image format (landscape) *"
              item={imageUrl}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Field
              label="Description *"
              name="description"
              multiline
              rows={6}
              component={Input}
            />
          </Grid>

          <Grid  item xs={12} lg={6}>
            <Field
              label="Choose event for content"
              name="livestream"
              component={Autocomplete}
              onChange={(value) => {
                handleChangeSearch({ label: value?.label });
                setFieldValue("eventId", value?.id);
              }}
              options={events ? events : []}
            />
          </Grid>
        </Grid>

        <Grid sx={{marginTop: "40px"}} container spacing={3}>
          <Grid item xs={12} sm={4} md={6} lg={5}>
            <Button
              type="button"
              onClick={() => {
                navigator("/featured");
              }}
            >
              Cancel
            </Button>
          </Grid>

          <Grid item xs={12} sm={8} md={6} lg={5}>
            <ButtonGroup
              sx={{
                gap: "3rem",
                marginTop: "auto",
                width: "100%",
                alignItems: "center",
                justifyContent: "flex-end",
                flexWrap: "wrap",
              }}
            >
              <Button
                onClick={handleSubmit}
                type="submit"
                isLoading={isLoading}
              >
                Save & Exit
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Form>
    </FormWrapper>
  );
};
export default ContentForm;
