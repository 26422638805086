import { useFormikContext } from "formik"
import { Box } from "@mui/material"

import Button from "../../components/common/button/Button"
import CurrencyInformationForm from "./currencyInformationForm/CurrencyInformationForm"
import PurchaseInformation from "./purchaseInformation/PurchaseInformation"
import BlockWithToggle from "../../components/common/blockWithToggle/BlockWithToggle"
import "./StylesSprayMoney.scss"
import { Field } from "formik"
import Input from "../../components/common/input/Input"
import { useCallback} from "react"
import { enqueueSnackbar } from "notistack"

const SprayMoneyForm = ({
  tmpPreview,
  setTmpPreview,
  isLoading,
  errors,
  touched,
}) => {
  const {
    values: {
      sprayMoneyCurrencyImage,
      sprayMoneyTopSenderIcon,
      chatUrl,
      bannerUrl,
    },
  } = useFormikContext()
  const firstError = Object.keys(errors)[0]
  const isValidStep = useCallback(() => {
    const errorsKeys = Object.keys(errors)
    const isHasError = errorsKeys.length > 0
    return isHasError
  }, [errors])

  return (
    <div className="SprayMoney">
      <div className="SprayMoney__forms">
        <Box
          sx={{
            borderBottom: "1px dashed #E7E6EB",
            paddingBottom: "2rem",
            margin: "15px 4.5rem 0 4.5rem",
          }}
        >
          <Field
            component={Input}
            label="Chat Url"
            name="chatUrl"
            values={chatUrl}
          />
        </Box>
        <BlockWithToggle title="Spray Money" name="isSprayMoneyEnabled">
          <div className="SprayMoney__content">
            <CurrencyInformationForm
              tmpPreview={tmpPreview}
              errors={errors}
              touched={touched}
              setTmpPreview={setTmpPreview}
              sprayMoneyCurrencyImage={sprayMoneyCurrencyImage}
              sprayMoneyTopSenderIcon={sprayMoneyTopSenderIcon}
            />
            <PurchaseInformation />
          </div>
        </BlockWithToggle>
      </div>
      <Box
        sx={{
          textAlign: "end",
          padding: "0 2rem 3rem",
        }}
      >
        <Button
          onClick={() => {
            if (isValidStep()) {
              return enqueueSnackbar(
                `${errors[firstError]}. Please resolve them before submitting`,
                {
                  variant: "error",
                }
              )
            }
          }}
          isLoading={isLoading}
          type="submit"
        >
          Save & Exit
        </Button>
      </Box>
      <Box
        style={{
          width: "100%",
          background: "#ededed",
        }}
      >
        <div style={{ padding: "2rem 4.5rem 4.5rem 4.5rem" }}>
          <h2>Banner</h2>
          <Field
            component={Input}
            label="Banner Image"
            values={bannerUrl}
            name="bannerUrl"
            error={errors?.bannerUrl && touched?.bannerUrl}
            helperText={errors?.bannerUrl}
          />
        </div>
      </Box>
    </div>
  )
}

export default SprayMoneyForm
