import {
  FormControl,
  FormLabel,
  Grid,
  RadioGroup,
  ButtonGroup,
  FormHelperText,
  Box,
} from "@mui/material";
import { Formik, Field, Form } from "formik";
import { useState, useEffect } from "react";

import { createAdminValidate } from "../../validates/createAdmin";
import PhoneInput from "../../components/phoneInput/PhoneInput";
import Input from "../../components/common/input/Input";
import Button from "../../components/common/button/Button";
import Select from "../../components/common/select/Select";
import RadioButton from "../../components/common/radioButton/RadioButton";
import FormWrapper from "../../components/common/formWrapper/FormWrapper";

const AddEditAdminForm = ({
  userGroups,
  submitFunction,
  userData,
  isLoading,
  navigator,
  isNewUser,
}) => {
  const [isNotyify, setIsNotyfy] = useState(false);
  const [defaultUserGroup, setDefaultUserGroup] = useState(null);

  useEffect(() => {
    const defaultValue = userGroups.find(
      ({ value }) => value === userData?.data.roleId
    );
    setDefaultUserGroup(() => defaultValue);
  }, [userData, userGroups]);

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          email: userData?.data?.email,
          status:
            userData?.data?.status === "PENDING"
              ? "DEACTIVATED"
              : "DEACTIVATED",
          firstName: userData?.data?.firstName,
          lastName: userData?.data?.lastName,
          phoneNumber: userData?.data?.phoneNumber,
          password: userData?.data?.password,
          roleId: userData?.data?.roleId,
          id: userData?.data?.id,
        }}
        onSubmit={(formValues) => {
          submitFunction(
            {
              email: formValues.email,
              status: formValues.status,
              firstName: formValues.firstName,
              lastName: formValues.lastName,
              phoneNumber: formValues.phoneNumber,
              password: formValues.password,
              roleId: formValues.roleId,
              confirmEmailUrl: `${process.env.REACT_APP_HOST}/resetPassword`,
              id: userData?.data?.id,
            },
            isNotyify
          );
        }}
        validationSchema={createAdminValidate}
        enableReinitialize
      >
        {({ errors, touched }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={5}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2rem",
                    }}
                  >
                    <Field
                      label="First Name *"
                      name="firstName"
                      component={Input}
                      error={errors?.firstName && touched?.firstName}
                      helperText={errors?.firstName}
                    />
                    <Field
                      label="Email Address"
                      name="email"
                      component={Input}
                      error={errors?.email && touched?.email}
                      helperText={errors?.email}
                    />
                    {!isLoading && defaultUserGroup?.value && (
                      <Field
                        label="Choose User Group"
                        name="roleId"
                        component={Select}
                        defaultValue={{
                          label: defaultUserGroup?.value,
                          value: defaultUserGroup?.value,
                        }}
                        error={errors?.roleId && touched?.roleId}
                        helperText={errors?.roleId}
                        options={userGroups}
                      />
                    )}
                    {isNewUser && (
                      <Field
                        label="Choose User Group"
                        name="roleId"
                        component={Select}
                        error={errors?.roleId && touched?.roleId}
                        helperText={errors?.roleId}
                        options={userGroups}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={5} spacing={3}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2rem",
                    }}
                  >
                    <Field
                      label="Last Name"
                      name="lastName"
                      component={Input}
                      error={errors?.lastName && touched?.lastName}
                      helperText={errors?.lastName}
                    />
                    <Field
                      label="Phone Number"
                      name="phoneNumber"
                      type="number"
                      error={errors?.phoneNumber && touched?.phoneNumber}
                      helperText={errors?.phoneNumber}
                      component={PhoneInput}
                      defaultValue={userData?.data?.phoneNumber}
                    />
                    <Box marginTop={"1.1rem"}>
                      <Field
                        label="Passowrd"
                        name="password"
                        type="password"
                        error={errors?.password && touched?.password}
                        helperText={errors?.password}
                        component={Input}
                        defaultValue={userData?.data?.password}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    error={errors?.status && touched?.status}
                    sx={{
                      display: "flex",
                    }}
                  >
                    {!isNewUser && (
                      <>
                        <FormLabel>Access Status</FormLabel>
                        <RadioGroup
                          row
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Box>
                            <Field
                              component={RadioButton}
                              name="status"
                              type="radio"
                              value="ACTIVE"
                              label="Active"
                            />
                            <Field
                              name="status"
                              component={RadioButton}
                              type="radio"
                              value={
                                userData?.data?.status === "PENDING"
                                  ? "DEACTIVATED"
                                  : "DEACTIVATED"
                              }
                              label="Inactive"
                            />
                          </Box>
                          <FormHelperText
                            sx={{
                              fontSize: "1rem",
                              margin: 0,
                            }}
                          >
                            {errors?.status}
                          </FormHelperText>
                        </RadioGroup>
                      </>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4} md={6} lg={5}>
                  <Button
                    type="button"
                    onClick={() => {
                      navigator("/admins");
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>

                <Grid item xs={12} sm={8} md={6} lg={5}>
                  <ButtonGroup
                    sx={{
                      gap: "3rem",
                      marginTop: "auto",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      isLoading={isLoading}
                      type="submit"
                      onClick={() => setIsNotyfy(false)}
                    >
                      Save & Exit
                    </Button>
                    <Button
                      isLoading={isLoading}
                      type="submit"
                      isModal
                      onClick={() => setIsNotyfy(true)}
                    >
                      Save & Notify
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </FormWrapper>
  );
};
export default AddEditAdminForm
