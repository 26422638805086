import {
  Select as MaterialSelect,
  MenuItem,
  FormHelperText,
  Box,
} from "@mui/material";
import ionicIcon from "../../../assets/icons/ioni-black.svg";
import { useState } from "react";
import "./StylesSelect.scss";

function Icon({ rotate, isDropdownOpen }) {
  return (
    <div className="icon-container">
      <img
        className={
          rotate || isDropdownOpen ? "rotateUp dropIcon" : "rotateDown dropIcon"
        }
        src={ionicIcon}
        alt="..."
      />
    </div>
  );
}

const Select = ({
  form,
  field,
  options,
  label,
  error,
  helperText,
  disabled, // Add disabled prop
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [rotate, setRotate] = useState(false);

  const handleRotate = () => {
    setRotate((prev) => !prev);
  };

  const handleDropdownOpen = () => {
    if (!disabled) {
      setIsDropdownOpen(true);
      handleRotate();
    }
  };

  const handleDropdownClose = () => {
    if (!disabled) {
      setIsDropdownOpen(false);
      handleRotate();
    }
  };

  return (
    <Box
      className="wrapperSelectBlock"
      sx={{
        width: "100%",
      }}
    >
      <label className="wrapperSelectBlock__label">{label}</label>
      <Box className="select-wrap">
        <MaterialSelect
          error={error}
          fullWidth
          value={field.value || ""}
          onFocus={handleDropdownOpen} // Handle focus event
          onBlur={handleDropdownClose} // Handle blur event
          disabled={disabled} // Apply disabled prop
          sx={{
            "& .MuiInputBase-input": {
              display: "flex",
              alignItems: "center",
              fontSize: "1.8rem",
            },
          }}
          IconComponent={() => (
            <Icon rotate={rotate} isDropdownOpen={isDropdownOpen} />
          )}
          onChange={(event) => {
            form.setFieldValue(field.name, event.target.value);
          }}
        >
          {options &&
            options.map((selectItem, i) => (
              <MenuItem
                sx={{ fontSize: "1.6rem" }}
                className="custom-item"
                key={i}
                value={selectItem.value}
              >
                {selectItem.label}
              </MenuItem>
            ))}
        </MaterialSelect>
        {error && (
          <FormHelperText
            sx={{
              color: "red",
              fontSize: "1.1rem",
            }}
          >
            {helperText}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export default Select;
