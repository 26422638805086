import { Form } from "formik"
import { useEffect, useState } from "react"

import FormWrapper from "../../components/common/formWrapper/FormWrapper"
import EventContentForm from "../eventContentForm/EventContentForm"
import EventInformationForm from "../eventInformationForm/EventInformationForm"
import SprayMoneyForm from "../sprayMoneyForm/SprayMoneyForm"
import StreamPassesForm from "../streamPassesForm/StreamPassesForm"

const EVENT_INFORMATION_TAB_INDEX = 0
const EVENT_CONTENT_TAB_INDEX = 1
const STREAM_PASSES_TAB_INDEX = 2
const SPRAY_MONEY_TAB_INDEX = 3

const EventForm = ({
  activeHeadNavMenuId,
  handleChangeActiveHeadMenu,
  errors,
  isLoading,
  dirty,
  touched,
}) => {
  const [tmpPreview, setTmpPreview] = useState({})
  useEffect(() => {
    const handleBeforeUnload = event => {
      event.preventDefault()
      event.returnValue = ""
    }

    if (dirty) window.addEventListener("beforeunload", handleBeforeUnload)

    return () => window.removeEventListener("beforeunload", handleBeforeUnload)
  }, [dirty])

  return (
    <FormWrapper>
      <Form>
        {activeHeadNavMenuId === EVENT_INFORMATION_TAB_INDEX && (
          <EventInformationForm
            errors={errors}
            touched={touched}
            tmpPreview={tmpPreview}
            setTmpPreview={setTmpPreview}
            handleChangeActiveHeadMenu={handleChangeActiveHeadMenu}
          />
        )}
        {activeHeadNavMenuId === EVENT_CONTENT_TAB_INDEX && (
          <EventContentForm
            tmpPreview={tmpPreview}
            touched={touched}
            setTmpPreview={setTmpPreview}
            handleChangeActiveHeadMenu={handleChangeActiveHeadMenu}
            errors={errors}
          />
        )}
        {activeHeadNavMenuId === STREAM_PASSES_TAB_INDEX && (
          <StreamPassesForm
            tmpPreview={tmpPreview}
            setTmpPreview={setTmpPreview}
            handleChangeActiveHeadMenu={handleChangeActiveHeadMenu}
            errors={errors}
            touched={touched}
          />
        )}
        {activeHeadNavMenuId === SPRAY_MONEY_TAB_INDEX && (
          <SprayMoneyForm
            isLoading={isLoading}
            tmpPreview={tmpPreview}
            touched={touched}
            setTmpPreview={setTmpPreview}
            errors={errors}
          />
        )}
      </Form>
    </FormWrapper>
  )
}

export default EventForm
