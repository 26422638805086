import { useCallback, Fragment, useState } from "react"
import { useDropzone } from "react-dropzone"
import { useFormikContext } from "formik"
import { useMutation } from "react-query"

import { uploadImage } from "../../../api/files"
import defaultDropZoneBackgroundImage from "../../../assets/images/CompositeLayer.png"
import "./StylesDropZone.scss"

let baseStyle = {
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: "250px",
  color: "#bdbdbd",
  outline: "none",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  transition: "border .24s ease-in-out",
}


const DropZone = ({ label = "Upload Artist Image" }) => {
  const [files, setFiles] = useState([])
  const { mutate } = useMutation(file => uploadImage(file))
  const {
    setFieldValue,
    values,
    // values: { image },
  } = useFormikContext()

  // const image = values.image
  const image = values.image ? values.image : values.artist?.photoImageUrl

  const onDrop = useCallback(async acceptedFiles => {
    mutate(acceptedFiles[0], {
      onSuccess: data => {
        setFieldValue("image", data?.data?.Location)
        setFiles(
          acceptedFiles.map(file =>
            Object.assign(file, {
              preview: data?.data?.Location || image,
            })
          )
        )
      },
    })
  }, [image,mutate,setFieldValue])

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
    },
    onDrop,
  })

  return (
    <Fragment>
      <div
        className="DropZone"
        {...getRootProps({
          style: {
            ...baseStyle,
            padding: "50%",
            backgroundImage:
              !files.length && !image
                ? `url(${defaultDropZoneBackgroundImage})`
                : `url(${image})`,
          },
        })}
      >
        <div className="DropZone__blackout" />
        <input {...getInputProps()} />
        <div className="DropZone__content">
          {!files.length && !image && <span>{label}</span>}
        </div>
      </div>
    </Fragment>
  )
}

export default DropZone
