import { fetchClient } from "../axiosinterceptor";

export const createContent = async (values) => {
  const { data } = await fetchClient().post("featured-content", {
    ...values,
  });

  return data;
};

export const editContent = async (values) => {
  const payload = {
    title: values?.title,
    description: values?.description,
    imageUrl: values?.imageUrl,
    videoUrl: values?.videoUrl,
    eventId: values?.eventId
  }
  const { data } = await fetchClient().put(
    `featured-content/${values?.id}`,
    {
      ...payload,
    }
  );

  return data;
};

export const deactivateContent = async ({ contentId }) => {
  const { data } = await fetchClient().delete(`featured-content/${contentId}`);

  return data;
};

export const getContentById = async ({ contentId }) => {
  const { data } = await fetchClient().get(`featured-content/${contentId}`);

  return data;
};

export const updateContentPosition = async ({ positions }) => {
  console.log({ positions });
  const { data } = await fetchClient().patch(
    `featured-content/swap-positions`,
    positions
  );
  return data;
};

export const getContents = async ({ page, limit }) => {
  const { data } = await fetchClient().get(
    `featured-content?limit=${limit}&page=${page}`
  );
  return data;
};
