import DropZone from "../common/dropZone/DropZone";
import "./StylesArtistInformationBlock.scss";
import UploadEventPrint from "../common/uploadEventPrint/UploadEventPrint";
import { useState } from "react";

const ArtistInformationBlock = ({ imgFieldName, imgType }) => {
  const [tmpPreview, setTmpPreview] = useState({});

  return (
    <div className="UserInformationBlock">
      <DropZone
        imgFieldName={imgFieldName}
        imgType={imgType}
        label="Add Artist Image"
      />
      <div className="UserInformationBlock__user">
        <div className="ArtistInformationBlock__cover__picture">
          <UploadEventPrint
            type={"vertical"}
            label={"Vertical Artist Cover Image"}
            subText="Image will be resized to width: 499px, height: 722px"
            blockName="artistCoverImageUrl"
            fieldName={`coverImageUrl`}
            tmpPreview={tmpPreview}
            setTmpPreview={setTmpPreview}
          />
        </div>
      </div>
    </div>
  );
};

export default ArtistInformationBlock;
