import { Formik } from "formik";
import { useMutation, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import Page from "../../components/common/page/Page";
import FormWrapper from "../../components/common/formWrapper/FormWrapper";
import { contentValidate } from "../../validates/contentValidate";
import ContentForm from "../../forms/contenrForm/ContentForm";
import { createContent, editContent, getContentById } from "../../api/contents/contents";
import { useEffect, useState } from "react";

const ManageFeaturedContent = () => {
  const navigator = useNavigate();
  const [contentId, setContentId] = useState("");
  const { isLoading, mutate } = useMutation((sendData) =>
    createContent(sendData)
  );

  const { data: contentData } = useQuery(
    ["content-Id", contentId],
    () => getContentById({ contentId }),
    {
      enabled: !!contentId,
    }
  );

  const { mutate: mutateEdit } = useMutation((values) => {
    return editContent(values);
  });

  const handleSubmit = (values) => {
    const valuesToEdit = {
      ...values,
      id: contentId,
    };
    if (contentId) {
      return mutateEdit(valuesToEdit, {
        onSuccess: () => {
          enqueueSnackbar("Content edited", { variant: "success" });
          navigator("/featured");
        },
      });
    }
    mutate(values, {
      onSuccess: () => {
        enqueueSnackbar("Content created", { variant: "success" });
        navigator("/featured");
      },
      onError: (error) => {
        const errorData = error?.response?.data?.data;

        errorData?.forEach(({ field, message }) =>
          enqueueSnackbar(`${field ? field + ":" : ""}${message}`, {
            variant: "error",
          })
        );
      },
    });
  };

  useEffect(() => {
    const params = new URL(window.location).searchParams;
    const id = params.get("id");

    if (id) {
      setContentId(id);
    }
  }, []);

  return (
    <Page title="Add/Manage Content">
      <FormWrapper>
        <Formik
          initialValues={{
            videoUrl: contentData?.data?.videoUrl || "",
            title: contentData?.data?.title || "",
            description: contentData?.data?.description || "",
            imageUrl: contentData?.data?.imageUrl || "",
            eventId: contentData?.data?.eventId || "",
          }}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={contentValidate}
        >
          {({ errors, values, touched }) => {
            return <ContentForm navigator={navigator} isLoading={isLoading} />;
          }}
        </Formik>
      </FormWrapper>
    </Page>
  );
};

export default ManageFeaturedContent;
