import { Box, TableRow } from "@mui/material";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LIMIT } from "../../constants";
import CustomTabelCell from "../../components/common/CustomTebleCell/CustomTableCell";
import TableFilter from "../../components/tableFilter/TableFIlter";
import useDebounce from "../../hooks/useDebounce";
import Table from "../../components/table/Table";
import ActionList from "../../components/actionsList/ActionList";
import ellipsisIcon from "../../assets/icons/ellipsis.png";
import Page from "../../components/common/page/Page";
import Button from "../../components/common/button/Button";
import editArtistIcon from "../../assets/icons/editLiveStreamIcon.png";
import deleteArtistIcon from "../../assets/icons/deleteEventIcon.png";
import { getAllArtist } from "../../api/artist";
import { getEvents } from "../../api/events";
import DeactivateArtistModal from "../../components/modals/deactivateArtistModal/DeactivateArtistModal";

const tableHeads = [
  { label: "Profile" },
  { label: "email" },
  { label: "website" },
  { label: "Upcoming Event" },
  { label: "No. of Events" },
  { label: ellipsisIcon, isIcon: true },
]


const Artists = () => {
  const navigator = useNavigate()
  const [isShowFilter, setIsShowFilter] = useState(false)
  const [Artists ] = useState([])
  const [isShowSearchField, setIsShowSearchField] = useState(false)
  const [isShowDeactivateModal, setIsShowDeactivateModal] = useState(false)
  const [artistId, setArtistId] = useState("")
  const [searchEvent, setSearchEvent] = useState("")
  const [filters, setFilters] = useState()
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: LIMIT,
  })
  const debouncedFilters = useDebounce(filters, 300)
console.log(searchEvent)
  const { data, refetch, error } = useQuery({
    retry: 0,
    queryKey: ["Artists"],
    queryFn: () =>
      getAllArtist({
        page: pagination.pageIndex,
        limit: LIMIT,
        search: filters?.search,
      }),
  })

  useQuery({
    retry: 0,
    queryKey: ["livestream-events"],
    queryFn: () =>
      getEvents({
        page: pagination.pageIndex,
        limit: LIMIT,
        search: filters?.search,
        artistId: filters?.artistId,
        status: filters?.status,
      }),
  })


  const handleCreateNewArtist = () => navigator("/createArtist")
  const handleEditArtist = artistId => {
    navigator(`/createArtist?id=${artistId}`)
  }

  const handleShowFilter = () => setIsShowFilter((prevState) => !prevState);
  const handleCloseDeactivateModal = () => setIsShowDeactivateModal(false);
  const handleOpenDeactivateModal = ( artistId) => {
    setArtistId(artistId)
    setIsShowDeactivateModal(true)
  }
  const handleShowSeachField = () =>
    setIsShowSearchField(prevState => !prevState)


  useEffect(() => {
    refetch()
  }, [pagination.pageIndex, debouncedFilters, refetch])

  const handleChangeFilter = (field, event) => {
    const value = event.target.value;
  
    if (field === "event") {
      setSearchEvent(value);
      const eventId = Artists?.find(({ label }) => label === value);
  
      setFilters((prevState) => ({
        ...prevState,
        eventId: eventId?.id, // Using static property name
      }));
      return;
    }
  
    setFilters((prevState) => ({
      ...prevState,
      [field]: value === "placeholder" ? "" : value,
    }));
  };
  

  return (
    <Page
      title="Artists"
      action={<Button onClick={handleCreateNewArtist}>Create New</Button>}
    >
      <Box>
        <TableFilter
          autocompliteData={Artists}
          hideFilter
          autocompliteTextName="event"
          autocomplitePlaceholder="Livestream Title"
          textFieldName="search"
          selectFieldName="status"
          handleFilters={handleChangeFilter}
          // selectOptions={filterUserStatus}
          textFieldPlaceholder="Search for Artist by stageName"
          handleShowFilter={handleShowFilter}
          isShowFilter={isShowFilter}
          handleShowSeachField={handleShowSeachField}
          isShowSearchField={isShowSearchField}
        />
        <Table
          pagination={pagination}
          setPagination={setPagination}
          page={pagination?.pageIndex}
          meta={data?.meta}
          headers={tableHeads}
          error={error}
          tableType="Users"
        >
          {data?.data.map(artist => {
            return (
              <TableRow
                key={artist.id}
                sx={{
                  background: "#FAFAFA 0% 0% no-repeat padding-box",
                  borderBottom: "7px solid white",
                }}
              >
                <CustomTabelCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      style={{
                        width: "50px",
                        height: "50px",
                        marginBottom: "10px",
                      }}
                      src={artist?.coverImageUrl}
                      alt=""
                    />
                    {artist.stageName}
                  </Box>
                </CustomTabelCell>
                <CustomTabelCell>{artist?.email}</CustomTabelCell>
                <CustomTabelCell>{artist?.website}</CustomTabelCell>
                <CustomTabelCell>{artist?.upComingEvent}</CustomTabelCell>
                <CustomTabelCell>{artist?.noOfArtists}</CustomTabelCell>
                <CustomTabelCell>
                  <ActionList
                    actions={[
                      {
                        label: "Edit Artist",
                        icon: editArtistIcon,
                        action: () => handleEditArtist(artist.id),
                      },
                      {
                        label: "Deactivate Artist",
                        icon: deleteArtistIcon,
                        action: () => handleOpenDeactivateModal(artist.id),
                      },
                    ]}
                  />
                </CustomTabelCell>
              </TableRow>
            )
          })}
        </Table>
      </Box>

      {isShowDeactivateModal && (
        <DeactivateArtistModal
          artistId={artistId}
          refetch={refetch}
          handleClose={handleCloseDeactivateModal}
        />
      )}
    </Page>
  )
}

export default Artists
