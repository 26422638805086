import { useNavigate } from "react-router-dom"

import Customers from "../cusomers/Customers"
import Page from "../../components/common/page/Page"
import Button from "../../components/common/button/Button"

const DashboardPage = () => {
  const navigator = useNavigate()

  const handleCreateNewCustomer = () => navigator("/userInformation")

  return (
    <Page
      title="Customers"
      action={<Button onClick={handleCreateNewCustomer}>Create New</Button>}
    >
      <Customers />
    </Page>
  )
}

export default DashboardPage
