import * as Yup from "yup";

export const createArtistValidate = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .nullable(),
  fullName: Yup.string().required("This field in required!"),
  biography: Yup.string().required("This field in required!"),
  recordLabel: Yup.string().required("This field in required!"),
  stageName: Yup.string().required("This field in required!"),
  image: Yup.string().required("This field in required!"),
  coverImageUrl: Yup.string().required("This field in required!"),
  facebookUrl: Yup.string().nullable().url(),
  instagramUrl: Yup.string().nullable().url(),
  twitterUrl: Yup.string().nullable().url(),
  videoUrl: Yup.string()
  .required("This field is required!")
  .url("Invalid URL format")
  .test(
    'is-https',
    'URL must start with https://',
    value => value && value.startsWith('https://')
  ),
  website: Yup.string().nullable().url(),
  countryCode: Yup.string().nullable(),
  phoneNumber: Yup.string().nullable(),
});
