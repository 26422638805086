import { useNavigate } from "react-router-dom";
import { Field, useFormikContext } from "formik";
import { Grid, Box, Typography } from "@mui/material";
import { getDaysForMonth } from "../../utils/getDaysForMonth";
import { getYearsList } from "../../utils/getYearsList";
import { MONTH } from "../../constants";
import Select from "../../components/common/select/Select";
import Input from "../../components/common/input/Input";
import UploadEventPrint from "../../components/common/uploadEventPrint/UploadEventPrint";
import ContinueButton from "../../components/common/ContinueButton/ContinueButton";
import { Fragment, useCallback, useEffect, useState } from "react";
import TimeField from "../../components/common/timeField/TimeField";
import dayjs from "dayjs";
import ToggleSquare from "../../components/common/toggleSwitch/ToggleSquare";

const EventInformationForm = ({
  handleChangeActiveHeadMenu,
  tmpPreview,
  setTmpPreview,
  errors,
  touched,
}) => {
  const navigate = useNavigate();

  const {
    values: {
      startYear,
      startMonth,
      endDay,
      startTime,
      endTime,
      startDay,
      endMonth,
      endYear,
      rights,
      printUrl,
      title,
      location,
      venue,
      vibes,
      eventMode,
    },
    setFieldValue,
  } = useFormikContext();

  const mappedContentType = {
    LIVESTREAM: "Livestream",
    ON_DEMAND: "On-Demand",
  };

  const [__eventMode, __setEventMode] = useState(mappedContentType[eventMode]);
  const startDays = getDaysForMonth(startYear, startMonth);
  const startYears = getYearsList(2010, new Date().getFullYear() + 50);
  const endYears = getYearsList(2010, new Date().getFullYear() + 60);
  const endDays = getDaysForMonth(endYear, endMonth);

  const isValidStep = useCallback(() => {
    const errorsKeys = Object.keys(errors);
    const fieldsKeys = [
      "startYear",
      "startMonth",
      "startTime",
      "endTime",
      "endDay",
      "startDay",
      "endMonth",
      "endYear",
      "rights",
      "location",
      "venue",
      "title",
      "vibes",
    ];
    const isHasError = errorsKeys.some(key => fieldsKeys.includes(key));

    return (
      title &&
      (eventMode === "LIVESTREAM" || eventMode === "ON_DEMAND") &&
      location &&
      venue &&
      vibes &&
      !isHasError
    );
  }, [errors, title, location, venue, eventMode, vibes]);

  const changeTab = tab => {
    const EVENT_CONTENT_TAB_INDEX = 1;
    handleChangeActiveHeadMenu(EVENT_CONTENT_TAB_INDEX);
    navigate(window.location.search.replace(/(tab)=[^&]*/g, `${tab}`));
  };

  const handleContinue = () => {
    changeTab("tab=eventContent");
  };

  const handleToggleForEventMode = value => {
    __setEventMode(value);
    if (value === "Livestream") {
      setFieldValue("eventMode", "LIVESTREAM");
    }
    if (value === "On-Demand") {
      setFieldValue("eventMode", "ON_DEMAND");
    }
  };

  useEffect(() => {
    if (eventMode === "LIVESTREAM" || eventMode === "ON_DEMAND") {
      setFieldValue("livestreamEnabled", true);
    }
  }, [eventMode, setFieldValue]);

  return (
    <Box>
      <Grid container rowSpacing={2}>
        <Grid xs={12} sm={12} md={12} lg={8} item>
          <Box
            sx={{
              gap: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Field
              component={Input}
              label="Live Stream Title *"
              name="title"
              error={touched.title && errors.title}
              helperText={errors?.title}
            />

            <Field
              component={Select}
              label="Livestream Rights*"
              name="rights"
              options={[
                { label: "Nigeria", value: "nigeria" },
                { label: "Africa", value: "africa" },
                { label: "Global", value: "global" },
              ]}
              defaultValue={{ value: rights }}
              error={touched.rights && !!errors?.rights}
              helperText={errors?.rights}
            />
            <Box>
              <Field
                label="Event Type"
                name="vibes"
                component={Select}
                defaultValue={{ value: vibes }}
                options={[
                  { label: "Africa to the world", value: "africa_world" },
                  {
                    label: "X-Live",
                    value: "x_live",
                  },
                  { label: "Nxt-up", value: "nxt_up" },
                ]}
                helperText={errors?.vibes}
                error={touched.vibes && !!errors?.vibes}
              />
            </Box>
            <Field
              component={Input}
              label="Livestream Location *"
              name="location"
              error={touched.location && !!errors?.location}
              helperText={errors?.location}
            />
            <Field
              component={Input}
              label="Venue Name *"
              name="venue"
              error={touched.venue && !!errors?.venue}
              helperText={errors?.venue}
            />
            <UploadEventPrint
              type={"horizontal"}
              tmpPreview={tmpPreview}
              mustBeLandscape
              setTmpPreview={setTmpPreview}
              fieldName="printUrl"
              label="Upload Cover Png File for Event Card"
              subText="Image will be resized to width: 499px, height: 722px"
              subText2="Recommended image format (landscape) *"
              item={printUrl}
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={4} item>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <ContinueButton
              handleClick={handleContinue}
              isDisabled={!isValidStep()}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", margin: "3rem 0" }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: "#000000", fontSize: "1.6rem" }}
          >
            Event Mode
          </Typography>
          <ToggleSquare
            sx={{
              width: "25%",
            }}
            leftLabel="Livestream"
            rightLabel="On-Demand"
            onClick={handleToggleForEventMode}
            active={__eventMode}
            noSpacing
          />
        </Box>

        <Fragment>
          <Box sx={{ margin: "3rem 0" }}>
            <label className="wrapperInputBlock__label">
              {__eventMode === "Livestream"
                ? "Livestream Start Time"
                : "On-Demand Start Time"}
            </label>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                width: "80%",
                margin: "1rem 0",
              }}
            >
              <Field
                component={Select}
                label="Day"
                name="startDay"
                options={startDays}
                defaultValue={{ value: startDay }}
                error={errors?.startDay}
                helperText={errors?.startDay}
              />
              <Field
                component={Select}
                label="Month"
                name="startMonth"
                defaultValue={{ value: startMonth }}
                options={MONTH}
                error={errors?.startMonth}
                helperText={errors?.startMonth}
              />
              <Field
                component={Select}
                label="Year"
                name="startYear"
                defaultValue={{ value: startYear }}
                options={startYears}
                error={errors?.startYear}
                helperText={errors?.startYear}
              />
              <TimeField
                label="Time"
                name="startTime"
                value={startTime ? dayjs(startTime) : ""}
                helperText={errors?.startTime}
                handleChange={time => {
                  setFieldValue("startTime", time);
                }}
                error={errors?.startTime}
              />
            </Box>
          </Box>
          <Box sx={{ margin: "3rem 0" }}>
            <label className="wrapperInputBlock__label">
              {__eventMode === "Livestream"
                ? "Livestream End Time"
                : "On-Demand End Time"}
            </label>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                width: "80%",
                margin: "1rem 0",
              }}
            >
              <Field
                component={Select}
                label="Day"
                name="endDay"
                options={endDays}
                error={errors?.endDay}
                helperText={errors?.endDay}
                defaultValue={{ value: endDay }}
              />
              <Field
                component={Select}
                label="Month"
                name="endMonth"
                options={MONTH}
                error={errors?.endMonth}
                helperText={errors?.endMonth}
                defaultValue={{ value: endMonth }}
                style={{ fontFamily: "Baguede" }}
              />
              <Field
                component={Select}
                label="Year"
                name="endYear"
                options={endYears}
                error={errors?.endYear}
                helperText={errors?.endYear}
                defaultValue={{ value: endYear }}
              />
              <TimeField
                label="Time"
                name="endTime"
                value={endTime ? dayjs(endTime) : ""}
                helperText={errors?.endTime}
                handleChange={time => {
                  setFieldValue("endTime", time);
                }}
                error={errors?.endTime}
              />
            </Box>
          </Box>
        </Fragment>
      </Box>
    </Box>
  );
};

export default EventInformationForm;
