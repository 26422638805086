import { TableCell } from '@mui/material';

const CustomTabelCell = ({ children, wraptext }) => (
  <TableCell 
    sx={{
      fontSize: '1.6rem',
      whiteSpace: wraptext ? 'wrap' : 'nowrap',
      align: 'center',
      fontFamily: "Baguede !important",
      fontWeight: 100,
      opacity: 0.87,
      color: "#272727"
    }}
    component='th' scope='row'
  >
    {children}
  </TableCell>
)

export default CustomTabelCell;
