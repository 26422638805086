import { useState } from "react";

import SideBarMenuItem from "./SideBarMenuItem/SideBarMenuItem";
import dashboardIcon from "../../assets/icons/dashboardIcon.svg";
import customersIcon from "../../assets/icons/cusomerIcon.svg";
import livestreamIcon from "../../assets/icons/liveStreamIcon.svg";
import transactionIcon from "../../assets/icons/transaction.svg";
import commsIcon from "../../assets/icons/commIcon.svg";
import crmIcon from "../../assets/icons/crmIcon.svg";
import addUserIcon from "../../assets/icons/addUserIcon.svg";
import settingsIcon from "../../assets/icons/settingsIcon.svg";

const sidebar = [
  { label: "Dashboard", url: "/dashboard", icon: dashboardIcon },
  {
    label: "Customers",
    url: "/dashboard",
    icon: customersIcon,
    hasNested: true,
    nested: [
      { label: "CRM", icon: crmIcon, url: "/dashboard" },
      { label: "Add new", icon: addUserIcon, url: "/userInformation" },
    ],
  },
  {
    label: "Livestreams",
    url: "/liveStream",
    icon: livestreamIcon,
    hasNested: true,
    nested: [
      { label: "Events", icon: crmIcon, url: "/liveStream" },
      {
        label: "Create Event",
        icon: addUserIcon,
        url: "/createNewLiveStream?tab=eventInformation",
      },
    ],
  },
  {
    label: "Featured",
    url: "/featured",
    icon: commsIcon,
    hasNested: true,
    nested: [
      { label: "Contents", icon: crmIcon, url: "/featured" },
      {
        label: "Manage Featured",
        icon: addUserIcon,
        url: "/createContent",
      },
    ],
  },
  {
    label: "Transactions",
    url: "/transactions",
    icon: transactionIcon,
    hasNested: true,
    nested: [
      { label: "Transactions", icon: crmIcon, url: "/transactions" },
      {
        label: "Create Transactions",
        icon: addUserIcon,
        url: "/createTransaction",
      },
    ],
  },
  {
    label: "Artists",
    url: "/artists",
    icon: addUserIcon,
    hasNested: true,
    nested: [
      { label: "Artists", icon: crmIcon, url: "/artists" },
      { label: "Create Artist", icon: addUserIcon, url: "/createArtist" },
    ],
  },
  {
    label: "Blogs",
    url: "/blogs",
    icon: commsIcon,
    hasNested: true,
    nested: [
      { label: "Blogs", icon: crmIcon, url: "/blogs" },
      { label: "Create Blog", icon: addUserIcon, url: "/createBlog" },
    ],
  },
  {
    label: "Comms",
    url: "/comms",
    icon: commsIcon,
    hasNested: true,
    nested: [
      { label: "Comms", icon: crmIcon, url: "/comms" },
      { label: "Create Comms", icon: addUserIcon, url: "/createEmail" },
    ],
  },
  {
    label: "Settings",
    url: "/admins",
    icon: settingsIcon,
    hasNested: true,
    nested: [{ label: "Groups", url: "/adminGroups", icon: "" }],
  },
];

const SideBarMenu = () => {
  const [activeTab, setActiveTab] = useState(null);

  const handleChangeActiveTab = (idTab) => setActiveTab(() => idTab);

  return (
    <ul>
      {sidebar &&
        sidebar.map((item, idx) => (
          <SideBarMenuItem
            idx={idx}
            key={idx}
            label={item.label}
            url={item.url}
            icon={item.icon}
            hasNested={item.hasNested}
            handleChangeActiveTab={handleChangeActiveTab}
            activeTab={activeTab}
            nested={item.nested}
          />
        ))}
    </ul>
  );
};

export default SideBarMenu;
