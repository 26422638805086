import { Box } from "@mui/material"
import { useFormikContext } from "formik"
import { useNavigate } from "react-router-dom"

import StreamPassesBlock from "./StreamPassesBlock/StreamPassesBlock"
import StreamInformationForm from "./streamInformationForm/StreamInformationForm"
import StreamPurchaseInformationForm from "./streamPurchaseInformation/StreamPurchaseInformationForm"
import "./StylesStreamPasses.scss"
import ContinueButton from "../../components/common/ContinueButton/ContinueButton"
import { useCallback, useEffect, useState } from "react"
import { Accordion } from "../../components/accordion/accordion"
import OndemandStreamInformationForm from "./onDemandStreamInformationForm/onDemandStreamInformationForm"
import OnDemandStreamPurchaseInformationForm from "./onDemandStreamPurchaseInformationForm/onDemandStreamPurchaseInformationForm"

const StreamPassesForm = ({
  handleChangeActiveHeadMenu,
  errors,
  tmpPreview,
  setTmpPreview,
}) => {
  const navigator = useNavigate()
  const {
    values: { passes, onDemandPasses, eventMode },
    setFieldValue,
  } = useFormikContext()
  const [disableLivestreamPasses, setDisableLivestreamPasses] = useState(false)
  const [disableOnDemandPasses, setDisableOnDemandPasses] = useState(false)
  const [liveStreamContent, setLiveStreamContent] = useState(false)
  const [editPage, setEditPage] = useState(false)
  const [onDemandContent, setOnDemandContent] = useState(false)
  const changeTab = tab => {
    const STREAM_PASSES_TAB_INDEX = 3
    handleChangeActiveHeadMenu(STREAM_PASSES_TAB_INDEX)
    navigator(window.location.search.replace(/(tab)=[^&]*/g, `${tab}`))
  }

  const handleContinue = () => changeTab("tab=livestreamContent")

  const isValidStep = useCallback(() => {
    return errors?.passes
  }, [errors])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const eventId = queryParams.get("eventId")
    if (eventId) {
      setEditPage(true)
      if (eventMode === "LIVESTREAM") {
        setDisableOnDemandPasses(true)
      }
      if (eventMode === "ON_DEMAND") {
        setDisableLivestreamPasses(true)
      }
    }
  }, [editPage, setFieldValue, eventMode])

  useEffect(() => {
    if (eventMode === "LIVESTREAM") {
      setLiveStreamContent(true)
      return
    } else if (eventMode === "ON_DEMAND") {
      setOnDemandContent(true)
    }
  }, [eventMode])

  return (
    <div className="SteamPasses">
      {(liveStreamContent || editPage) && (
        <Accordion itemPadding headerTitle={"Livestream Passes"}>
          <div className="section__subtitle">
            Set up Streampasses for this event
          </div>
          <StreamPassesBlock
            title="Does this event have a livestream broadcast?"
            name={
              disableLivestreamPasses ? "forceDisable" : `passes[0].isEnabled`
            }
            passIndex={0}
            passes={passes}
          >
            <div className="SteamPasses__livetsream__forms">
              <StreamPurchaseInformationForm
                passIndex={0}
                passes={passes ? passes : []}
                setTmpPreview={setTmpPreview}
                tmpPreview={tmpPreview}
              />
              <StreamInformationForm
                passIndex={0}
                passes={passes ? passes : []}
                setTmpPreview={setTmpPreview}
                tmpPreview={tmpPreview}
              />
            </div>
          </StreamPassesBlock>
          <StreamPassesBlock
            title="Does this event have a backstage Stream?"
            name={
              disableLivestreamPasses ? "forceDisable" : `passes[1].isEnabled`
            }
            passIndex={1}
            passes={passes}
          >
            <div className="SteamPasses__livetsream__forms">
              <StreamPurchaseInformationForm
                passIndex={1}
                passes={passes ? passes : []}
                setTmpPreview={setTmpPreview}
                tmpPreview={tmpPreview}
              />
              <StreamInformationForm
                passIndex={1}
                passes={passes ? passes : []}
                setTmpPreview={setTmpPreview}
                tmpPreview={tmpPreview}
              />
            </div>
          </StreamPassesBlock>
          <StreamPassesBlock
            title="Does this event have exclusive content"
            name={
              disableLivestreamPasses ? "forceDisable" : `passes[2].isEnabled`
            }
            passIndex={2}
            passes={passes}
          >
            <div className="SteamPasses__livetsream__forms">
              <StreamPurchaseInformationForm
                passIndex={2}
                passes={passes ? passes : []}
                setTmpPreview={setTmpPreview}
                tmpPreview={tmpPreview}
              />
              <StreamInformationForm
                passIndex={2}
                passes={passes ? passes : []}
                setTmpPreview={setTmpPreview}
                tmpPreview={tmpPreview}
              />
            </div>
          </StreamPassesBlock>
          <Box
            sx={{
              textAlign: "end",
              padding: "0 2rem 3rem",
            }}
          >
            <ContinueButton
              handleClick={handleContinue}
              isDisabled={isValidStep()}
            />
          </Box>
        </Accordion>
      )}
      {(onDemandContent || editPage) && (
        <Accordion itemPadding headerTitle={"On-Demand Passes"}>
          <div className="section__subtitle">
            Set up Streampasses for this event
          </div>
          <StreamPassesBlock
            title="Does this event have a stream-on demand"
            name={
              disableOnDemandPasses
                ? "forceDisable"
                : `onDemandPasses[0].isEnabled`
            }
            passIndex={0}
            onDemandPasses={onDemandPasses}
          >
            <div className="SteamPasses__livetsream__forms">
              <OnDemandStreamPurchaseInformationForm
                passIndex={0}
                onDemandPasses={onDemandPasses ? onDemandPasses : []}
                setTmpPreview={setTmpPreview}
                tmpPreview={tmpPreview}
              />
              <OndemandStreamInformationForm
                passIndex={0}
                onDemandPasses={onDemandPasses ? onDemandPasses : []}
                setTmpPreview={setTmpPreview}
                tmpPreview={tmpPreview}
              />
            </div>
          </StreamPassesBlock>
          <StreamPassesBlock
            title="Does this event have a backstage on-demad"
            name={
              disableOnDemandPasses
                ? "forceDisable"
                : `onDemandPasses[1].isEnabled`
            }
            passIndex={1}
            onDemandPasses={onDemandPasses}
          >
            <div className="SteamPasses__livetsream__forms">
              <OnDemandStreamPurchaseInformationForm
                passIndex={1}
                onDemandPasses={onDemandPasses ? onDemandPasses : []}
                setTmpPreview={setTmpPreview}
                tmpPreview={tmpPreview}
              />
              <OndemandStreamInformationForm
                passIndex={1}
                onDemandPasses={onDemandPasses ? onDemandPasses : []}
                setTmpPreview={setTmpPreview}
                tmpPreview={tmpPreview}
              />
            </div>
          </StreamPassesBlock>
          <StreamPassesBlock
            title="Should have exclusive content"
            name={
              disableOnDemandPasses
                ? "forceDisable"
                : `onDemandPasses[2].isEnabled`
            }
            passIndex={2}
            onDemandPasses={onDemandPasses}
          >
            <div className="SteamPasses__livetsream__forms">
              <OnDemandStreamPurchaseInformationForm
                passIndex={2}
                onDemandPasses={onDemandPasses ? onDemandPasses : []}
                setTmpPreview={setTmpPreview}
                tmpPreview={tmpPreview}
              />
              <OndemandStreamInformationForm
                passIndex={2}
                onDemandPasses={onDemandPasses ? onDemandPasses : []}
                setTmpPreview={setTmpPreview}
                tmpPreview={tmpPreview}
              />
            </div>
          </StreamPassesBlock>
          <Box
            sx={{
              textAlign: "end",
              padding: "0 2rem 3rem",
            }}
          >
            <ContinueButton
              handleClick={handleContinue}
              isDisabled={isValidStep()}
            />
          </Box>
        </Accordion>
      )}
    </div>
  )
}

export default StreamPassesForm
