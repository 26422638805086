import * as Yup from "yup";

export const contentValidate = Yup.object().shape({
  title: Yup.string().required("This field is required!"),
  eventId: Yup.string().required("This field is required!"),
  description: Yup.string().required("This field is required!"),
  videoUrl: Yup.string()
    .required("This field is required!")
    .url("Invalid URL format")
    .test(
      'is-https',
      'URL must start with https://',
      value => value && value.startsWith('https://')
    ),
  imageUrl: Yup.string()
    .required("This field is required!")
    .url("Invalid URL format"),
});
