import axios from "axios"

const BASE_URL = process.env.REACT_APP_BASE_URL

export const fetchClient = () => {
  const token = JSON.parse(localStorage.getItem("token"))
  const defaultOptions = {
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  const instance = axios.create(defaultOptions)
  instance.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      // Handle response errors
      if (error?.response.status === 401) {
        localStorage.removeItem("user")
        window.location.href = "/login"
      }
      return Promise.reject(error)
    }
  )
  return instance
}
