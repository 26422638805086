import { Field } from "formik";

import Input from "../../../components/common/input/Input";
import "./onDemandStreamPurchaseInformationForm.scss";
import { Box, Typography } from "@mui/material";
import UploadEventPrint from "../../../components/common/uploadEventPrint/UploadEventPrint";
import Select from "../../../components/common/select/Select";

const OnDemandStreamPurchaseInformationForm = ({
  passIndex,
  onDemandPasses,
  tmpPreview,
  setTmpPreview,
}) => {
  return (
    <div className="StreamPurchaseInformationForm">
      {/* <div className="StreamPurchaseInformationForm__title">
        <span>Purchase Information</span>
      </div> */}
      <div className="StreamPurchaseInformationForm__form">
        <Box style={{ margin: "2px 0 8px 0" }}>
          <Typography variant="h6">Stream Pass Cover</Typography>
          <UploadEventPrint
            type="vertical"
            label={`Pass Cover`}
            subText="Image will be resized to width: 499px, height: 722px"
            blockName="onDemandPasses"
            fieldName={`onDemandPasses[${passIndex}].passCoverUrl`}
            tmpPreview={tmpPreview}
            setTmpPreview={setTmpPreview}
            item={onDemandPasses[passIndex]?.passCoverUrl}
          />
        </Box>
        <div className="StreamPurchaseInformationForm__form__wrapper__checkbox--group">
          <span className="StreamPurchaseInformationForm__form__checkbox--group--label">
            This Stream Pass should be available to purchase in?
          </span>
          <div className="StreamPurchaseInformationForm__form__checkbox--group">
            <label className="formikCheckBox">
              <Field
                type="checkbox"
                name={`onDemandPasses[${passIndex}].enableNGN`}
                checked={onDemandPasses?.[passIndex]?.enableNGN}
              />
              NGN
            </label>
            <label className="formikCheckBox">
              <Field
                type="checkbox"
                name={`onDemandPasses[${passIndex}].enableGBP`}
                checked={onDemandPasses?.[passIndex]?.enableGBP}
              />
              GBP
            </label>
            <label className="formikCheckBox">
              <Field
                type="checkbox"
                name={`onDemandPasses[${passIndex}].enableUSD`}
                checked={onDemandPasses?.[passIndex]?.enableUSD}
              />
              USD
            </label>
            <label className="formikCheckBox">
              <Field
                type="checkbox"
                name={`onDemandPasses[${passIndex}].enableMTN`}
                checked={onDemandPasses?.[passIndex]?.enableMTN}
              />
              MTN
            </label>
          </div>
          <div className="StreamPurchaseInformationForm__form__wrapper__input--group">
            {/* <span className="StreamPurchaseInformationForm__form__checkbox--group--label">
              Allow Payment Methods
            </span> */}
            <div className="StreamPurchaseInformationForm__form__input--group">
                <Field
                  component={Input}
                  disabled={!onDemandPasses[passIndex]?.enableNGN}
                  label="Price in NGN"
                  name={`onDemandPasses[${passIndex}].priceNGN`}
                  subText="Per Stream Pass"
                  defaultValue={onDemandPasses?.[passIndex]?.priceNGN}
                />
              <Field
                component={Input}
                disabled={!onDemandPasses[passIndex]?.enableGBP}
                label="Price in GBP"
                name={`onDemandPasses[${passIndex}].priceGBP`}
                subText="Per Stream Pass"
                defaultValue={onDemandPasses?.[passIndex]?.priceGBP}
              />
              <Field
                component={Input}
                disabled={!onDemandPasses[passIndex]?.enableUSD}
                label="Price in USD"
                name={`onDemandPasses[${passIndex}].priceUSD`}
                subText="Per Stream Pass"
                defaultValue={onDemandPasses?.[passIndex]?.priceUSD}
              />
              <Field
                component={Input}
                disabled={!onDemandPasses[passIndex]?.enableMTN}
                label="Price in MTN"
                name={`onDemandPasses[${passIndex}].priceMTN`}
                subText="Per Stream Pass"
                defaultValue={onDemandPasses?.[passIndex]?.priceMTN}
              />
            </div>
          </div>
          <div className="StreamPurchaseInformationForm__form__wrapper__input--group">
            <span className="StreamPurchaseInformationForm__form__checkbox--group--label">
              Allow Payment Methods
            </span>
            <div className="StreamPurchaseInformationForm__form__input--group">
              <label className="formikCheckBox">
                <Field
                  type="checkbox"
                  name={`onDemandPasses[${passIndex}].allowStripe`}
                  checked={onDemandPasses[passIndex]?.allowStripe}
                />
                Stripe
              </label>
              <label className="formikCheckBox">
                <Field
                  type="checkbox"
                  name={`onDemandPasses[${passIndex}].allowPaystack`}
                  checked={onDemandPasses[passIndex]?.allowPaystack}
                />
                Paystack
              </label>
              <label className="formikCheckBox">
                <Field
                  type="checkbox"
                  name={`onDemandPasses[${passIndex}].allowMTN`}
                  checked={onDemandPasses[passIndex]?.allowMTN}
                />
                MTN
              </label>
            </div>
          </div>
          <div style={{ width: "100%" }}>
          <Field
              component={Select}
              label="MTN Service ID"
              name={`onDemandPasses[${passIndex}].mtnServiceId`}
              options={[
                { label: "Tier 1 (N1500)", value: "23410220000023487" },
                { label: "Tier 2 (N1000)", value: "23410220000023488" },
                { label: "Tier 3 (N500)", value: "23410220000023489" },
              ]}
              defaultValue={{value: onDemandPasses?.[passIndex]?.mtnServiceId}}
              disabled={!onDemandPasses[passIndex]?.allowMTN}
            />
          </div>
          <div className="StreamPurchaseInformationForm__form__wrapper__input--group">
            <span
              style={{ marginTop: "10px" }}
              className="StreamPurchaseInformationForm__form__checkbox--group--label"
            >
              User Purchase Limit from store
            </span>
            <div className="StreamPurchaseInformationForm__form__input--group">
              <Field
                component={Input}
                label="Per User Account"
                name={`onDemandPasses[${passIndex}].maxPerUser`}
                defaultValue={onDemandPasses?.[passIndex]?.maxPerUser}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OnDemandStreamPurchaseInformationForm;
