import { fetchClient } from '../axiosinterceptor';

export const getUserTransactions = async ({ limit, page, userId }) => {
  const { data } = await fetchClient().get(`/orders/${userId}/limit/${limit}/page/${page}`);

  return data;
}

export const getTransactions = async ({ limit, page, status, eventId, search }) => {

  const { data } = await fetchClient().get(`orders/limit/${limit}/page/${page}`, {
    params: {
      status: status === 'SPRAY_MONEY' || status === 'STREAM_PASS' ? 'SUCCESS' : status,
      eventId,
      s: search,
      order_type: status === 'SPRAY_MONEY' || status === 'STREAM_PASS' ? status : undefined,
    }
  });

  return data;
};


export const createTransaction = async ({ values, isNotify }) => {
  const { data } = await fetchClient().post('orders/', {
    ...values,
  })

  return data;
}