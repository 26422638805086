import { Box, TableRow } from "@mui/material";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { getEvents } from "../../api/events";
import { getTransactions } from "../../api/transactions";
import bankTransferIcon from "../../assets/icons/bankTransferIcon.png";
import checkCircleIcon from "../../assets/icons/checkCircleIcon.svg";
import deliveryIcon from "../../assets/icons/deliveryIcon.png";
import ellipsisIcon from "../../assets/icons/ellipsis.png";
import errorCircleIcon from "../../assets/icons/errorCircleIcon.svg";
import pendingCircleIcon from "../../assets/icons/iconPending.svg";
import transactionIcon from "../../assets/icons/transactionIcon.png";
import validatePendingIcon from "../../assets/icons/validatePendingIcon.png";
import viewOrderIcon from "../../assets/icons/viewOrderIcon.png";
import ActionList from "../../components/actionsList/ActionList";
import Button from "../../components/common/button/Button";
import CustomTabelCell from "../../components/common/CustomTebleCell/CustomTableCell";
import Page from "../../components/common/page/Page";
import CompeteOrderModal from "../../components/modals/completeOrderModal/CompeteOrderModal";
import ProcessRefundModal from "../../components/modals/processRefundModal/ProcessRefundModal";
import ReopenOrderModal from "../../components/modals/reopenOrderModal/ReopenOrderModal";
import ValidatePendingModal from "../../components/modals/validatePendingModal/ValidatePendingModal";
import Table from "../../components/table/Table";
import TableFilter from "../../components/tableFilter/TableFIlter";
import TransactionInformation from "../../components/transactionInformation/TransactionInformation";
import { LIMIT } from "../../constants";
import useDebounce from "../../hooks/useDebounce";
import { getTransactionById } from "../../utils/getTransactionById";

const filterTransactionStatus = [
  { label: "All - Payment status", value: "placeholder" },
  { label: "Success", value: "SUCCESS" },
  { label: "Failed", value: "FAILED" },
  { label: "Canceled", value: "CANCELED" },
  { label: "Pending", value: "PENDING" },
];

const filterOrderType = [
  { label: "All - Purchase Type", value: "placeholder" },
  { label: "Spray Money", value: "SPRAY_MONEY" },
  { label: "Stream Pass", value: "STREAM_PASS" },
];

const tableHeads = [
  { label: "OrderID" },
  { label: "User Email" },
  { label: "Created At" },
  { label: "Purchased at" },
  { label: "Type" },
  { label: "Items" },
  { label: "Status" },
  { label: ellipsisIcon, isIcon: true },
];

const Transactions = () => {
  const navigator = useNavigate();
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: LIMIT,
  });
  const [searchEvent, setSearchEvent] = useState("");
  const [events, setEvents] = useState([]);
  const [selectTransaction, setSelectTransaction] = useState({});
  const [isShowTransactionInfo, setIsShowTransactionInfo] = useState(false);
  const [isShowProcessRefundModal, setIsShowProcessRefundModal] = useState(false);
  const [isShowCompleteOrderModal, setIsShowCompleteOrderModal] = useState(false);
  const [isShowReopenOrderModal, setIsShowReopenOrderModal] = useState(false);
  const [isShowValidatePendingModal, setIsShowValidatePendingModal] = useState(false);

  const debouncedSearchEvent = useDebounce(searchEvent, 300);
  const debouncedFilters = useDebounce(filters, 300);

  const { data: eventData, refetch: eventRefetch } = useQuery({
    retry: 0,
    queryKey: ["events"],
    queryFn: () =>
      getEvents({
        page: pagination?.pageIndex,
        limit: LIMIT,
        search: searchEvent,
      }),
  });

  const { data, refetch, error } = useQuery({
    retry: 0,
    queryKey: ["transactions"],
    queryFn: () =>
      getTransactions({
        page: pagination.pageIndex,
        limit: LIMIT,
        status: filters?.status,
        eventId: filters?.eventId,
        search: filters?.search,
      }),
  });

  useEffect(() => {
    eventRefetch();
  }, [debouncedSearchEvent, eventRefetch]);

  useEffect(() => {
    refetch();
  }, [pagination.pageIndex, debouncedFilters, filters?.status, refetch]);

  useEffect(() => {
    if (eventData) {
      setEvents(() =>
        eventData?.data.map((event) => ({
          label: event?.title,
          id: event.id,
        }))
      );
    }
  }, [eventData]);

  const handleCloseTransactionInfo = () => setIsShowTransactionInfo(false);
  const handleCloseProcessRefundModal = () => setIsShowProcessRefundModal(false);
  const handleCloseCompleteOrderModal = () => setIsShowCompleteOrderModal(false);
  const handleCloseReopenOrderModal = () => setIsShowReopenOrderModal(false);
  const handleCloseValidatePendingModal = () => setIsShowValidatePendingModal(false);

  const handleCreateNewTransaction = useCallback(() => navigator("/createTransaction"), [navigator]);

  const handleOpenReopenOrderModal = useCallback(
    (transactionId) => {
      if (data) {
        const transaction = getTransactionById(data, transactionId);
        setSelectTransaction(transaction);
        setIsShowReopenOrderModal(true);
      }
    },
    [data]
  );

  const handleOpenCompleteOrderModal = useCallback(
    (transactionId) => {
      if (data) {
        const { data: transactionData } = data;
        const transaction = getTransactionById(transactionData, transactionId);
        setSelectTransaction(transaction);
        setIsShowCompleteOrderModal(true);
      }
    },
    [data]
  );

  const handleShowTransactionInfo = useCallback(
    (transactionId) => {
      if (data) {
        const { data: transactionData } = data;
        const transaction = getTransactionById(transactionData, transactionId);
        setSelectTransaction(transaction);
        setIsShowTransactionInfo(true);
      }
    },
    [data]
  );

  const handleOpenProcessRefundModal = useCallback(
    (transactionId) => {
      if (data) {
        const { data: transactionData } = data;
        const transaction = getTransactionById(transactionData, transactionId);
        setSelectTransaction(transaction);
        setIsShowProcessRefundModal(true);
      }
    },
    [data]
  );

  const handleChangeFilter = (field, event) => {
    const value = event.target.value;
    if (field === "event") {
      setSearchEvent(value);
      const eventId = events?.find(({ label }) => label === value);

      return setFilters((prevState) => ({
        ...prevState,
        eventId: eventId?.id,
      }));
    }

    const statusValue = value === "placeholder" ? undefined : value;
    setFilters((prevState) => ({
      ...prevState,
      [field]: statusValue,
    }));
  };

  const handleValidatePending = useCallback(
    (transaction) => {
      setIsShowValidatePendingModal(true);
      setSelectTransaction(transaction);
    },
    []
  );

  const actionsPageButton = useMemo(
    () => <Button onClick={handleCreateNewTransaction}>Create Transaction</Button>,
    [handleCreateNewTransaction]
  );

  return (
    <Page title="Transactions" action={actionsPageButton}>
      {isShowReopenOrderModal && (
        <ReopenOrderModal
          selectTransactionId={selectTransaction?.id}
          handleClose={handleCloseReopenOrderModal}
          refetch={refetch}
        />
      )}
      {isShowCompleteOrderModal && (
        <CompeteOrderModal
          handleClose={handleCloseCompleteOrderModal}
          selectTransactionId={selectTransaction?.id}
          refetch={refetch}
        />
      )}
      {isShowProcessRefundModal && (
        <ProcessRefundModal
          order={selectTransaction}
          handleCloseProcessRefundModal={handleCloseProcessRefundModal}
          selectTransactionId={selectTransaction?.id}
          refetch={refetch}
        />
      )}
      {isShowTransactionInfo && (
        <TransactionInformation
          onClose={handleCloseTransactionInfo}
          selectTransaction={selectTransaction}
        />
      )}
      {isShowValidatePendingModal && (
        <ValidatePendingModal
          selectTransactionId={selectTransaction?.id}
          handleClose={handleCloseValidatePendingModal}
          refetch={refetch}
        />
      )}
      <Box>
        <TableFilter
          autocompliteData={events}
          autocompliteTextName="event"
          autocomplitePlaceholder="Livestream Title"
          handleFilters={handleChangeFilter}
          textFieldPlaceholder="Search for Customer"
          selectOptions={filterTransactionStatus}
          purchaseTypeOptions={filterOrderType}
          textFieldName="search"
          selectFieldName="status"
        />
        <Table
          pagination={pagination}
          setPagination={setPagination}
          page={pagination?.pageIndex}
          meta={data?.meta}
          error={error}
          headers={tableHeads}
        >
          {data &&
            data.map((transaction) => (
              <TableRow
                key={transaction?.id}
                style={{ fontFamily: "Baguede" }}
                sx={{
                  background: "#FAFAFA 0% 0% no-repeat padding-box",
                  borderBottom: "7px solid white",
                }}
              >
                <CustomTabelCell>{transaction?.id}</CustomTabelCell>
                <CustomTabelCell>{transaction?.user?.email}</CustomTabelCell>
                <CustomTabelCell>
                  {transaction?.createdAt
                    ? moment(transaction?.createdAt).format("DD:MM:YYYY")
                    : "-"}
                </CustomTabelCell>
                <CustomTabelCell>
                  {transaction?.transaction?.date
                    ? moment(transaction?.transaction?.date).format("DD:MM:YYYY")
                    : "-"}
                </CustomTabelCell>
                <CustomTabelCell>
                  {transaction?.orderItem[0]?.type === "STREAM_PASS"
                    ? "Stream Pass"
                    : "Spray Money"}
                </CustomTabelCell>
                <CustomTabelCell>{transaction?.orderItem?.length}</CustomTabelCell>
                <CustomTabelCell>
                  {transaction?.status === "SUCCESS" ? (
                    <Box>
                      <img src={checkCircleIcon} alt="circle" />
                    </Box>
                  ) : transaction?.status === "PENDING" ? (
                    <Box>
                      <img src={pendingCircleIcon} alt="circle" />
                    </Box>
                  ) : transaction?.status === "FAILED" ? (
                    <Box>
                      <img src={errorCircleIcon} alt="circle" />
                    </Box>
                  ) : (
                    <Box>
                      <img src={errorCircleIcon} alt="circle" />
                    </Box>
                  )}
                </CustomTabelCell>
                <CustomTabelCell>
                  <ActionList
                    actions={[
                      {
                        label: "View Order",
                        icon: viewOrderIcon,
                        action: () => handleShowTransactionInfo(transaction?.id),
                        status: transaction.status,
                      },
                      {
                        label: "Refund Order",
                        icon: validatePendingIcon,
                        action: () => handleOpenProcessRefundModal(transaction?.id),
                        status: transaction.status,
                      },
                      {
                        label: "Mark as Complete",
                        icon: transactionIcon,
                        action: () => handleOpenCompleteOrderModal(transaction?.id),
                        status: transaction.status,
                      },
                      {
                        label: "Reopen Order",
                        icon: deliveryIcon,
                        action: () => handleOpenReopenOrderModal(transaction?.id),
                        status: transaction.status,
                      },
                      {
                        label: "Validate Pending",
                        icon: bankTransferIcon,
                        action: () => handleValidatePending(transaction),
                        status: transaction.status,
                      },
                    ]}
                  />
                </CustomTabelCell>
              </TableRow>
            ))}
        </Table>
      </Box>
    </Page>
  );
};

export default Transactions;
