import { Field, FieldArray } from "formik";
import { Box } from "@mui/material";

import Input from "../../../components/common/input/Input";
import "./onDemandStylesStreamInformationForm.scss";
import UploadEventPrint from "../../../components/common/uploadEventPrint/UploadEventPrint";
import AddFieldButton from "../../../components/common/addFieldButton/AddFieldButton";

const OndemandStreamInformationForm = ({
  passIndex,
  onDemandPasses,
  setTmpPreview,
  tmpPreview,
}) => {
  return (
    <div className="StreamInformationForm">
      {/* <div className="StreamInformationForm__title">
        <span>Stream Information</span>
      </div> */}
      <div className="StreamInformationForm__form">
        <Field
          component={Input}
          label="What should this stream pass be called"
          name={`onDemandPasses[${passIndex}].title`}
          defaultValue={onDemandPasses?.[passIndex]?.title}
        />
        <Field
          component={Input}
          multiline
          rows={3}
          label="Purchase Description"
          name={`onDemandPasses[${passIndex}].description`}
          defaultValue={onDemandPasses?.[passIndex]?.description}
        />
          <Field
            component={Input}
            label="We’ll need on-demand stream pass URL"
            name={
                `onDemandPasses[${passIndex}].streamUrl`
            }
            defaultValue={onDemandPasses?.[passIndex]?.streamUrl}
          />
          {(passIndex === 0 || passIndex === 1) && (
            <Field
              component={Input}
              multiline
              rows={8}
              label="Describe this stream pass on the event page"
              name={`onDemandPasses[${passIndex}].eventPageDescription`}
              defaultValue={onDemandPasses?.[passIndex]?.eventPageDescription}
            />
          )}
       
        {passIndex === 2 ? (
          <Box>
            <FieldArray
              name={`onDemandPasses[${passIndex}].videoContent`}
              render={(arrayHelpers) => {
                return (
                  <Box>
                    <Box>
                      {onDemandPasses &&
                        onDemandPasses[passIndex]?.videoContent?.map(
                          (item, index) => {
                            return (
                              <Box
                                key={index}
                                sx={{
                                  marginTop: "3rem",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1,
                                }}
                              >
                                <Field
                                  component={Input}
                                  label={`Video Title ${
                                    index + 1
                                  } (Event Page)`}
                                  name={`onDemandPasses[${passIndex}].videoContent[${index}].videoTitle`}
                                  defaultValue={
                                    onDemandPasses?.[passIndex]?.streamUrl
                                  }
                                />
                                <Field
                                  component={Input}
                                  label="Add video Description (Event Page)"
                                  multiline
                                  rows={8}
                                  name={`onDemandPasses[${passIndex}].videoContent[${index}].videoDescription`}
                                  defaultValue={
                                    onDemandPasses[passIndex]?.videoDescription
                                  }
                                />
                                <Field
                                  component={Input}
                                  label="VIdeo URL"
                                  name={`onDemandPasses[${passIndex}].videoContent[${index}].videoUrl`}
                                  defaultValue={
                                    onDemandPasses[passIndex]?.videoUrl
                                  }
                                />
                                <UploadEventPrint
                                  type="horizontal"
                                  label={`Cover Image Video ${index + 1}`}
                                  subText="Image will be resized to width: 499px, height: 722px"
                                  blockName="streamPassVideoContentUrls"
                                  fieldName={`onDemandPasses[${passIndex}].videoContent[${index}].videoCoverArt`}
                                  tmpPreview={tmpPreview}
                                  index={index}
                                  nestedBlockName={onDemandPasses}
                                  setTmpPreview={setTmpPreview}
                                  item={
                                    onDemandPasses[passIndex]?.videoContent[
                                      index
                                    ].videoCoverArt
                                  }
                                />
                              </Box>
                            );
                          }
                        )}
                    </Box>
                    <AddFieldButton
                      text="Add Video Field"
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          videoTitle: "",
                          videoDescription: "",
                          videoUrl: "",
                          videoCoverArt: ""
                        })
                      }
                    />
                  </Box>
                );
              }}
            />
          </Box>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default OndemandStreamInformationForm;
