import { Link } from 'react-router-dom';
import {useCallback} from "react";

import './StylesHeadNavBarItem.scss';

const HeadNavBarItem = ({ label, url, activeHeadNavMenuId, handleChangeActiveHeadMenu, idx, isDisableChangeTab }) => {

    const handleClick = useCallback(() => {
        if(isDisableChangeTab && ((activeHeadNavMenuId + 1) <= idx )) {
            return
        }
        handleChangeActiveHeadMenu(idx)
    }, [isDisableChangeTab, idx, handleChangeActiveHeadMenu, activeHeadNavMenuId])

    return (
        <Link className={`HeadNavBarItemWrapper ${isDisableChangeTab && ((activeHeadNavMenuId + 1) <= idx ) ? 'disable-link' : ''}`} to={url}>
            <li
                onClick={handleClick}
                className={`HeadNavBarItem ${idx === activeHeadNavMenuId ? 'active' : ''}`}
            >
                {label}
            </li>
        </Link>
    )
}

export default HeadNavBarItem;